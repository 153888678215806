import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Student } from 'src/app/shared/models/student';
import { UtilService } from 'src/app/shared/services/util.service';
import { lang } from 'src/lang/lang';
import { APIResponse } from 'src/app/shared/models/api-response';
import { HandleRequestService } from 'src/app/shared/services/handle-request.service';

declare var $;

@Component({
  selector: 'app-bourdon',
  templateUrl: './bourdon.component.html',
  styleUrls: ['./bourdon.component.css']
})
export class BourdonComponent implements OnInit {
  student: Student;
  lang = lang;
  locale = 'es';
  seconds = 0;
  timer = null;
  isFinished = true;
  isInited = false;
  findingNum = null;
  randomArray: number[][] = [];
  selectionArray: boolean[][] = [];
  isMobile = false;

  t = 0;
  nn = 0;
  cc = 0;
  n = 0;
  mm = 0;
  ss = 0;
  pp = 0;
  oo = 0;

  aa = 0.0;
  ee = 0.0;
  au = 0.0
  kk = 0.0;


  constructor(
    private httpService: HandleRequestService,
    private route: ActivatedRoute,
    private utilService: UtilService) { }

  ngOnInit() {

    if (window.innerWidth < 500) {
      this.isMobile = true;
    }

    var ln = localStorage.getItem('locale');
    if(ln != null) {
      this.locale = ln;
    }

    this.route.data.subscribe((data: Data) => {
      if (data.hasOwnProperty('profile')) {
        const response = data.profile as APIResponse;
        this.student = response.data as Student;
        this.startingTest();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.timer != null) {
      clearInterval(
        this.timer
      );
    }
  }


  generateRandomArray(rows: number, cols: number, specialDigit: number ) {
    this.randomArray = [];
    for (let i = 0; i < rows; i++) {
      const row = [];
      this.selectionArray[i] = [];
      for (let j = 0; j < cols; j++) {
        this.selectionArray[i][j] = false;
        
        let randomPos = Math.floor(Math.random() * 5) + (this.isMobile? 0:2);
        if (j >= 0 && j < randomPos) {
          row.push(specialDigit);
        } else {
          let randomDigit:number;
          do {
            randomDigit = Math.floor(Math.random() * 10); // Generate random digits from 0 to 9
          } while (randomDigit === specialDigit);
          row.push(randomDigit);
        }
      }
      this.shuffleArray(row);
      this.randomArray.push(row);
    }
  }

  shuffleArray(array: number[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  }

  startingTest() {
    if (!this.isFinished) {
      return;
    }
    document.documentElement.scrollTop = 0; 
    this.seconds = 180;
    this.showingTime();

    this.isFinished = false;
    this.isInited = true;
    this.findingNum = Math.floor(Math.random() * 10);
    if (this.isMobile) {
      this.generateRandomArray(80, 20, this.findingNum);
    } else {
      this.generateRandomArray(40, 40, this.findingNum);
    }
  }

  startTest() {
    this.isFinished = false;
    this.isInited = false;
    this.seconds = 180;
    if (this.isMobile) {
      this.generateRandomArray(80, 20, this.findingNum);
    } else {
      this.generateRandomArray(40, 40, this.findingNum);
    }

    this.timer = setInterval(
      () => {
        this.seconds--;
        this.showingTime();
        if (this.seconds == 0) {
          this.submitTest();
        }
      }, 1000
    );
  }

  submitTest() {
    this.isFinished = true;
    if (this.timer != null) {
      clearInterval(
        this.timer
      );
    }

    this.t = 180 - this.seconds;
    this.nn = 0;
    this.n = 0;
    this.mm = 0;
    this.ss = 0;
    this.oo = 0;
    this.pp = 0;

    let count = 0;
    let lastPosX = 0;
    for (let i = 0; i < this.randomArray.length; i++) {
      let viewed = false;
      for (let j = 0; j < this.randomArray[i].length; j++) {
        count++;

        if (this.randomArray[i][j] == this.findingNum) {
          this.n++;
        }

        if (this.selectionArray[i][j]) {
          this.nn = count;
          viewed = true;
          this.mm++;
          if (this.randomArray[i][j] == this.findingNum) {
            this.ss++;
          } else {
            this.oo++;
          }
          lastPosX = j;
        }
      }

      if (viewed) {
        this.cc = i + 1;
      }
    }

    for (let i = 0; i < this.cc; i++) {
      for (let j = 0; j < this.randomArray[i].length; j++) {
        if (!this.selectionArray[i][j] && this.randomArray[i][j] == this.findingNum) {
          this.pp++;
        }
        if (i == this.cc - 1 && j == lastPosX) {
          break;
        }
      }
    }
    this.aa = Number((this.nn / this.t).toFixed(2));
    this.ee = Number((this.nn * this.ss / this.n).toFixed(2));
    this.au = Number(((this.nn / this.t) * ((this.mm - (this.oo + this.pp)) / this.n)).toFixed(2));
    this.kk = Math.round(((this.mm - this.oo) * 100) / this.n);

    this.httpService.post('/account/bourdon/create', {
      t: this.t,
      nn: this.nn,
      cc: this.cc,
      n: this.n,
      mm: this.mm,
      ss: this.ss,
      pp: this.pp,
      oo: this.oo,
      aa: this.aa,
      ee: this.ee,
      au: this.au,
      kk: this.kk
    }).then(
      (res: APIResponse) => {
        
      }, () => {
      }
    );
  }

  showingTime() {
    let minute = new Date(this.seconds * 1000).toISOString().slice(14, 16);
    let second = new Date(this.seconds * 1000).toISOString().slice(17, 19);
    document.getElementById("minute-1").setAttribute("class","num-"+minute.substr(0,1));
    document.getElementById("minute-2").setAttribute("class","num-"+minute.substr(1,1));
    document.getElementById("second-1").setAttribute("class","num-"+second.substr(0,1));
    document.getElementById("second-2").setAttribute("class","num-"+second.substr(1,1));
  }

  showedTime() {
    return  new Date(this.seconds * 1000).toISOString().slice(14, 19);
  }

  selectedNum(i=0, j=0) {
    if (!this.isFinished && !this.isInited) {
      this.selectionArray[i][j] = !this.selectionArray[i][j]
    }
  }
}
