import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';
import { ProfileComponent } from './profile/profile.component';
import { LevelComponent } from './level/level.component';
import { ClassroomComponent } from './classroom/classroom.component';
import { HomeworkComponent } from './homework/homework.component';
import { MatchroomComponent } from './matchroom/matchroom.component';
import { SetAvatarComponent } from './set-avatar/set-avatar.component';
import { StudentProfileResolver } from '../shared/resolvers/get-student-profile-resolver.service';
import { LogoutComponent } from './logout.component';
import { GetAllLevelsResolver } from '../shared/resolvers/get-all-levels-resolver.service';
import { StudentProgressResolver } from '../shared/resolvers/get-student-progress-resolver.service';
import { NotificationComponent } from './notification/notification.component';
import { GetNotificationsResolver } from '../shared/resolvers/get-notifications-resolver.service';
import { QuestionsComponent } from './questions/questions.component';
import { GetHomeworkQuestionsResolver } from '../shared/resolvers/get-homework-questions-resolver.service';
import { GetNextLessonResolver } from '../shared/resolvers/get-next-lesson-resolver.service';
import { MatchQuestionsComponent } from './match-questions/match-questions.component';
import { StudentAuthGuardService } from '../shared/services/auth/student-auth-guard.service';
import { HallofameComponent } from './hallofame/hallofame.component';
import { BourdonComponent } from './bourdon/bourdon.component';
import { SmartpdfComponent } from './smartpdf/smartpdf.component';
import { Toddler7Component } from './toddler7/toddler7.component';
import { Runner1Component } from './runner1/runner1.component';
import { Toddler6Component } from './toddler6/toddler6.component';
import { Toddler8Component } from './toddler8/toddler8.component';

const routes: Routes = [
    { path: '', children: [
        { path: '', component: DashboardHomeComponent,  canActivate: [ StudentAuthGuardService ], resolve: {
            profile: StudentProfileResolver,
            progress: StudentProgressResolver
        } },
        { path: 'dashboard/avatar', component: SetAvatarComponent,resolve: {
            profile: StudentProfileResolver,
        } },
        { path: 'dashboard/smartpdf', component: SmartpdfComponent,resolve: {
            profile: StudentProfileResolver,
        } },
        { path: 'dashboard/smartpdf/toddler6', component: Toddler6Component,resolve: {
            profile: StudentProfileResolver,
        } },
        { path: 'dashboard/smartpdf/toddler7', component: Toddler7Component,resolve: {
            profile: StudentProfileResolver,
        } },
        { path: 'dashboard/smartpdf/toddler8', component: Toddler8Component,resolve: {
            profile: StudentProfileResolver,
        } },
        { path: 'dashboard/smartpdf/runner1', component: Runner1Component,resolve: {
            profile: StudentProfileResolver,
        } },
        { path: 'dashboard/profile', component: ProfileComponent, resolve: {
            profile: StudentProfileResolver,
            progress: StudentProgressResolver
        } },
        { path: 'dashboard/hallofame', component: HallofameComponent, resolve: {
            profile: StudentProfileResolver,
            progress: StudentProgressResolver
        } },
        { path: 'dashboard/levels', component: LevelComponent, resolve: {
            levels: GetAllLevelsResolver,
            profile: StudentProfileResolver,
            progress: StudentProgressResolver
        } },
        { path: 'dashboard/classroom', component: ClassroomComponent, resolve: {
            // levels: GetAllLevelsResolver,
            profile: StudentProfileResolver,
            progress: StudentProgressResolver
        } },
        { path: 'dashboard/homework', component: HomeworkComponent, resolve: {
            levels: GetAllLevelsResolver,
            profile: StudentProfileResolver,
            progress: StudentProgressResolver
        } },
        { path: 'dashboard/matchroom', component: MatchroomComponent, resolve: {
            profile: StudentProfileResolver,
            progress: StudentProgressResolver
        } },
        { path: 'dashboard/messages', component: NotificationComponent, resolve: {
            notifications: GetNotificationsResolver
        } },
        { path: 'dashboard/bourdon-testing', component: BourdonComponent, resolve: {
        } },
        { path: 'dashboard/:category/:level/:lesson', component: QuestionsComponent, resolve: {
            levels: GetAllLevelsResolver,
            profile: StudentProfileResolver,
            progress: StudentProgressResolver,
            questions: GetHomeworkQuestionsResolver,
            next: GetNextLessonResolver
        } },
        { path: 'dashboard/match/:matchID', component: MatchQuestionsComponent, resolve: {
            profile: StudentProfileResolver,
            progress: StudentProgressResolver
        } }
    ], resolve: { profile: StudentProfileResolver } },
    { path: 'dashboard/logout', component: LogoutComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule {}
