import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { APIResponse } from '../models/api-response';
import { Observable, from } from 'rxjs';
import { HandleRequestService } from '../services/handle-request.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PaymentPlansResolver implements Resolve<APIResponse> {
    constructor(private makeRequest: HandleRequestService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): APIResponse | Observable<APIResponse> | Promise<APIResponse> {
        return from(this.makeRequest.get('/plans')).pipe(
            map((x: APIResponse) => x)
        );
    }
}
