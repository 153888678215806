import { Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APIResponse } from 'src/app/shared/models/api-response';
import { Student } from 'src/app/shared/models/student';
import { lang } from 'src/lang/lang';
import { HandleRequestService } from 'src/app/shared/services/handle-request.service';
declare var $;

@Component({
  selector: 'app-set-avatar',
  templateUrl: './set-avatar.component.html',
  styleUrls: ['./set-avatar.component.css']
})
export class SetAvatarComponent implements OnInit {
  student: Student;
  studentProgress: any;
  lang = lang;
  locale = 'es';

  currentAvatar: any[] = [];
  currentAvatarIds: string[];
  avatars: {};
  purchasedItems: string[];
  purchasingAvatar: any;
  coin = 0;
  currentKey = 0;
  updating = false;

  constructor(
    private httpService: HandleRequestService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    var ln = localStorage.getItem('locale');
    if(ln != null) {
      this.locale = ln;
    }
    this.route.data.subscribe(x => {
      if (x.hasOwnProperty('profile')) {
        const response = x.profile as APIResponse;
        this.student = response.data as Student;
        this.currentAvatar = this.student.currentAvatar;
        this.purchasedItems = this.student.purchasedItems;
        this.currentAvatarIds = this.student.avatarItems;
        this.coin = this.student.points;
        this.avatars = {};
        const deselectableTypes = [
          'under_hair',
          'glasses',
          'backitem',
          'jackets',
          'polo',
          'watchs'
      ];
        for(let [type, avatars] of Object.entries(this.student.avatars)) {
          this.avatars[type] = [];
          for(let item of avatars) {
            let id: string = `${item._id}`;
              if(this.currentAvatarIds.includes(id)) {
                  item.selected = true;
              } else {
                  item.selected = false;
              }
  
              if(this.purchasedItems.includes(id)) {
                  item.purchased = true;
              } else {
                  item.purchased = false;
              }
  
              if(item.price > this.coin) {
                  item.available = false;
              } else {
                  item.available = true;
                  if(item.price == 0) {
                      item.purchased = true;
                  }
              }
  
              if(deselectableTypes.includes(item.type)) {
                  item.deselectable = true;
              } else {
                  item.deselectable = false;
              }
  
              this.avatars[type].push(item);
          }
        }
      }

      if (x.hasOwnProperty('progress')) {
        const response = x.progress as APIResponse;
        if (!response.error) {
          this.studentProgress = response.data;
        }
      }
    });
  }

  getAvatar() {
    if (typeof  this.student.avatar != "object") {
      return this.student.avatar;
    } else {
      return 'assets/images/avatar.png';
    }
  }

  selectItem(avatar) {
    for(let i in this.avatars[avatar.type]) {
        if(avatar._id == this.avatars[avatar.type][i]._id) {
            this.avatars[avatar.type][i].selected = true;
        } else {
            this.avatars[avatar.type][i].selected = false;
        }
    }
    this.currentAvatar = [];
    this.currentAvatarIds = [];
    for(let avatars of Object.values(this.avatars)) {
        for(let item of avatars as [any]) {
            if(item.selected) {
              this.currentAvatar.push(item);
              this.currentAvatarIds.push(item._id);
            }
        }
    }
  }

  deselectItem(avatar) {
    for(let i in this.avatars[avatar.type]) {
        if(avatar._id == this.avatars[avatar.type][i]._id) {
            this.avatars[avatar.type][i].selected = false;
        }
    }

    this.currentAvatar = [];
    this.currentAvatarIds = [];

    for(let avatars of Object.values(this.avatars)) {
        for(let item of avatars as [any]) {
            if(item.selected) {
                this.currentAvatar.push(item);
                this.currentAvatarIds.push(item._id);
            }
        }
    }
  }

  update() {
    if (this.updating) {
      return;
    }
    this.updating = true;
    this.httpService.post('/account/avatar/update', this.currentAvatarIds).then(
      (res: APIResponse) => {
        this.updating = false;
      }, () => {
        this.updating = false;
      }
    );
  }

  buyItem(avatar) {
    if(this.coin >= avatar.price) {
        this.purchasingAvatar = avatar;
        $('#payment-modal').modal();
    }
  }

  purchaseItem() {
    this.purchasedItems.push(this.purchasingAvatar._id);
    this.coin = this.coin - this.purchasingAvatar.price;

    this.httpService.post('/account/avatar/purchase', {
      price: this.purchasingAvatar.price,
      items: this.purchasedItems
    }).then(
      (res: APIResponse) => {
        for(let i in this.avatars[this.purchasingAvatar.type]) {
          if(this.purchasingAvatar._id == this.avatars[this.purchasingAvatar.type][i]._id) {
              this.avatars[this.purchasingAvatar.type][i].purchased = true;
              break;
          }
      }
      }, () => {
      }
    );

  }

  changeAvatar() {
    this.router.navigate(['/dashboard/avatar']);
  }
}
