import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Student } from 'src/app/shared/models/student';
import { STUDENT_AUTH } from 'src/app/shared/services/app-config';
import { UtilService } from 'src/app/shared/services/util.service';
import { NotificationResponse } from '../../shared/models/notification';
import { lang } from 'src/lang/lang';
import { APIResponse } from 'src/app/shared/models/api-response';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  student: Student;
  notificationResponse: NotificationResponse;
  notifications;
  lang = lang;
  locale = 'es';
  constructor(private route: ActivatedRoute,
              private utilService: UtilService) { }

  ngOnInit() {

    var ln = localStorage.getItem('locale');
    if(ln != null) {
      this.locale = ln;
    }

    this.route.data.subscribe((data: Data) => {
      if (data.hasOwnProperty('notifications')) {
        this.notificationResponse = data.notifications;
        this.formatMessage();
      }

      if (data.hasOwnProperty('profile')) {
        const response = data.profile as APIResponse;
        this.student = response.data as Student;
      }
    });
  }

  getAvatar() {
    
    if (typeof  this.student.avatar != "object") {
      return this.student.avatar;
    } else {
      return 'assets/images/avatar.png';
    }
  }

  formatMessage() {
    this.notifications = [];
    this.notificationResponse.data.notifications.forEach(notification => {
      const startIndex = notification.message.search('{{');
      const endIndex = notification.message.search('}}');
      if (startIndex > -1) {
        const identifier = notification.message.slice((startIndex + 2), endIndex);
        const entity = identifier.replace('{{', '').replace('}}', '');
        const user = this.notificationResponse.data.entities[entity].subject;
        notification.message = notification.message.replace(`{{${identifier}}}`, user);
        notification.date = new Date(+notification.date);
      }
      this.notifications.push(notification);
    });
  }

}
