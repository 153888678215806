import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/shared/services/util.service';
import { PAYMENT_PLAN, APP_NAME } from 'src/app/shared/services/app-config';
import { Router } from '@angular/router';
import { PaymentPlan } from 'src/app/shared/models/payment-plan';
import { environment } from 'src/environments/environment';
import { HandleRequestService } from 'src/app/shared/services/handle-request.service';
import { APIResponse } from 'src/app/shared/models/api-response';
import { HttpErrorResponse } from '@angular/common/http';

declare var paypal;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  plan: PaymentPlan;

  constructor(private utilService: UtilService,
              private router: Router,
              private httpService: HandleRequestService) { }

  ngOnInit() {
    this.utilService.retrieveLocalStorage(PAYMENT_PLAN).subscribe(plan => {
      if (!plan) {
        this.router.navigate(['/payment-plan']);
      } else {
        this.plan = plan as PaymentPlan;
      }
    });

    paypal
    .Buttons({
      createOrder: (data, actions) => {
        return actions.order.create({
          application_context: {
            shipping_preference: 'NO_SHIPPING',
            brand_name: APP_NAME,
            locale: 'en-US',
            user_action: 'CONTINUE',
          },
          intent: 'CAPTURE',

          purchase_units: [
            {
              description: 'Subscription for ' + this.plan.duration + ' month' + (this.plan.duration > 1 ? 's' : ''),
              amount: {
                value: this.plan.price,
              },
            },
          ],
        });
      },
      onApprove: (data, actions) => {
        this.utilService.appIsLoading.next(true);
        this.httpService.post(`/subscription/capture`, { order_id: data.orderID }).then(
          (res: APIResponse) => {
            this.utilService.appIsLoading.next(false);
            if (res.error) {
              this.utilService.showMessage('Request failed - ' + res.msg);
            } else {
              this.utilService.removeLocalStorageValue(PAYMENT_PLAN).subscribe(x => {
                this.router.navigate(['/dashboard/avatar']);
              });
            }
          }, (httpError: HttpErrorResponse) => {
            this.utilService.appIsLoading.next(false);
            this.utilService.showMessage('Request failed - ' + httpError.error.message);
          }
        );
      },
    })
    .render('#paypal-button-container');
  }

}
