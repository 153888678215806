import { Component, OnInit } from '@angular/core';
import { lang } from 'src/lang/lang';
@Component({
  selector: 'app-desktop-nav',
  templateUrl: './desktop-nav.component.html',
  styleUrls: ['./desktop-nav.component.css']
})
export class DesktopNavComponent implements OnInit {
  lang = lang;
  locale = 'es';
  constructor() { }

  ngOnInit() {
    var ln = localStorage.getItem('locale');
    if(ln != null) {
      this.locale = ln;
    }
  }

}
