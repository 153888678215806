import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { MakeHttpRequest } from './http.service';

@Injectable()
export class HandleRequestService {
    constructor(private makeHttpCall: MakeHttpRequest,
                private router: Router) { }

    post(urlParam, postValue) {
        const promise = new Promise(
            (resolve, reject) => {
                this.makeHttpCall.postToServer(urlParam, postValue).subscribe(
                    (response: HttpResponse<object>) => {
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            }
        );

        return promise;
    }

    patch(urlParam, id, postValue) {
        const promise = new Promise(
            (resolve, reject) => {
                this.makeHttpCall.updateRequest(`${urlParam}/${id}`, postValue).subscribe(
                    (response: HttpResponse<object>) => {
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                ) ;
            }
        );

        return promise;
    }

    get(urlParam) {
        const promise = new Promise(
            (resolve, reject) => {
                this.makeHttpCall.requestFromServer(urlParam).subscribe(
                    (response: HttpResponse<object>) => {
                        // tslint:disable-next-line: triple-equals
                        if (response.type != 0) {
                            resolve(response.body);
                        }
                    },
                    (error) => {
                        reject(error);
                    }
                );
            }
        );

        return promise;
    }

    getOne(urlParam, id) {
        const promise = new Promise(
            (resolve, reject) => {
                this.makeHttpCall.requestFromServer(`${urlParam}/${id}`).subscribe(
                    (response: HttpResponse<object>) => {
                        // tslint:disable-next-line: triple-equals
                        if (response.type != 0) {
                            resolve(response.body);
                        }
                    },
                    (error) => {
                        reject(error);
                    }
                );
            }
        );

        return promise;
    }
}
