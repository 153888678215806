import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StudentAuthService } from './student-auth.service';


@Injectable()
export class StudentAuthGuardService implements CanActivate {
    constructor(private router: Router,
                private authService: StudentAuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.authService.isAuthenticated().then(
            (setupUp: boolean) => {
                if (!setupUp) {
                    this.router.navigate(['/login']);
                } else {
                    return true;
                }
            }
        );
    }
}
