import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from 'src/app/shared/services/util.service';
import { PAYMENT_PLAN } from 'src/app/shared/services/app-config';
import { PaymentPlan } from '../../shared/models/payment-plan';

@Component({
  selector: 'app-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.css']
})
export class SelectPlanComponent implements OnInit {
  plans: PaymentPlan[] = [];
  plan: PaymentPlan;

  constructor(private route: ActivatedRoute,
              private utilService: UtilService,
              private router: Router) { }

  ngOnInit() {
    this.route.data.subscribe(x => {
      if (x.hasOwnProperty('response')) {
        if (!x.response.error) {
          const plans = x.response.data.plans;
          this.plans = plans;
        }
      }
    });
  }

  handleChecked(planID) {
    this.plan = this.plans.find(x => x._id === planID);
  }

  setPlan() {
    this.utilService.setValueToLocalStorage(PAYMENT_PLAN, this.plan, () => {
      this.router.navigate(['/payment']);
    });
  }

}
