import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { APIResponse } from 'src/app/shared/models/api-response';
import { Student } from 'src/app/shared/models/student';
import { Level } from 'src/app/shared/models/student_level';
import { Question, QuestionResponse } from 'src/app/shared/models/question';
import { HandleRequestService } from 'src/app/shared/services/handle-request.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { from } from 'rxjs';
import { bufferCount } from 'rxjs/operators';
import io from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { STUDENT_AUTH } from 'src/app/shared/services/app-config';
import { Abacus } from '../questions/abacus';

declare var Swiper;
declare var $;

@Component({
  selector: 'app-questions',
  templateUrl: './match-questions.component.html',
  styleUrls: ['./match-questions.component.css']
})
export class MatchQuestionsComponent implements OnInit, AfterViewInit, OnDestroy {
  levelInfo: Level;
  student: Student;
  studentProgress: any;
  nextLesson: any;
  lessonNumber;
  questionResponse: QuestionResponse[] = [];
  questionMeta: any;
  staticQuestions: Question[] = [];
  chunkNumber = 6;
  chunks = [];
  countPassed = 0;
  isCheckClicked = false;
  audibleQuestions: Question[] = [];
  flashQuestions: Question[] = [];
  allParticipants: Student[] = [];
  activeTab;
  voices = [];
  synth;
  speakingRate = 1;
  flashRate = 1000;
  currentPosition = 0;
  isWrong;
  @ViewChild('audibleAnswer', { static: false }) audibleAnswer: ElementRef;
  @ViewChild('flashAnswer', { static: false }) flashAnswer: ElementRef;
  mathematicalSigns = ['*', '-', '+', '/'];
  mathematicalWords = ['times', 'minus', 'plus', 'divide by'];
  flashCurrentCharacter;
  flashIsStart = false;
  displayInterval;
  noProgress = false;
  showAbacus = false;
  category;
  socket: any;
  isClassActive;
  matchEnded;
  message;
  challengeID;
  totalScore = 0;
  myScore: {
    static: number,
    audible: number,
    flash: number
  } = {
    static: 0,
    audible: 0,
    flash: 0
  };
  isCreator = false;
  challengeScore = [];
  challengedOn;

  constructor(private route: ActivatedRoute,
              private handleRequest: HandleRequestService,
              private utilService: UtilService,
              private router: Router,
              private ngZone: NgZone) {
    this.utilService.retrieveLocalStorage(STUDENT_AUTH).subscribe(
      (stud: Student) => {
        this.socket = io(`${environment.domain}/matchroom`, {
          query: {
            token: stud.token,
          },
        });
        this.socketEvents();
      }
    );
  }

  ngOnInit() {
    this.challengeID = this.route.snapshot.params.matchID;

    if ('speechSynthesis' in window) {
      this.synth = window.speechSynthesis;
      this.populateVoiceList();
    }

    this.ngZone.runOutsideAngular(() => {
      const abacusStatus = new Abacus('abacusStatic', 0);
      abacusStatus.init();
    });

    this.route.data.subscribe((data: Data) => {
      if (data.hasOwnProperty('profile')) {
        const response = data.profile as APIResponse;
        if (!response.error) {
          this.student = response.data;
        }
      }

      if (data.hasOwnProperty('progress')) {
        const response = data.progress as APIResponse;
        if (!response.error) {
          this.studentProgress = response.data;
          this.noProgress = Object.keys(this.studentProgress).length === 0 && this.studentProgress.constructor === Object;
        }
      }
    });

    this.populateVoiceList();
  }

  ngAfterViewInit() {
    // tslint:disable-next-line: no-unused-expression
    new Swiper('.swiper-container', {
      direction: 'horizontal',
      loop: false,
      pagination: {
          el: '.swiper-pagination',
      },
      navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
      },
      scrollbar: {
          el: '.swiper-scrollbar',
      },
    });
  }

  ngOnDestroy(): void {
    $('.modal').modal('hide');
    $('#classEndedModal').modal('hide');
    this.isCreator = false;
    this.matchEnded = true;
    this.socket.close();
  }

  socketEvents() {
    this.socket.on('connect', () => {
      this.isClassActive = true;
      this.socket.emit('get_match', this.challengeID);
    });

    this.socket.on('disconnect', () => {
      this.message = 'The match was disconnected for some reason';
      this.isClassActive = false;
      if (!this.matchEnded) {
        $('#classEndedModal').modal('show');
      }
    });

    this.socket.on('get_match', (challenge) => {
      this.isCreator = challenge.challenger_id === this.student._id;
      this.allParticipants = [...challenge.defenders, challenge.challenger];
      this.socket.emit('start_match', this.challengeID);
    });

    this.socket.on('match_finished', (challenge) => {
      this.matchEnded = true;
      this.challengeScore = challenge.scores;
      this.challengedOn = new Date(+challenge.challenged_at);
      $('#grandResult').modal('show');
    });

    this.socket.on('match_started', (challenge) => {
      this.socket.emit('generate_questions', challenge.challenge_id);
    });

    this.socket.on('questions', (questions) => {
      this.getQuestions(questions);
    });

    // this.socket.on('message', (message) => console.log(message));

    this.socket.on('f_error', (error) => {
      this.message = error;
      this.isClassActive = false;
      $('#classEndedModal').modal('show');
    });

    this.socket.on('error', (error) => {
      this.message = error;
      this.isClassActive = false;
      $('#classEndedModal').modal('show');
    });
  }

  getAvatar() {
    
    if (typeof  this.student.avatar != "object") {
      return this.student.avatar;
    } else {
      return 'assets/images/avatar.png';
    }
  }

  getQuestions(allQuestion) {
    this.staticQuestions = [];
    this.audibleQuestions = [];
    this.flashQuestions = [];

    const staticQuestions = allQuestion.s;
    const audibleQuestions = allQuestion.a;
    const flashQuestions = allQuestion.f;

    this.totalScore = staticQuestions.length + audibleQuestions.length + flashQuestions.length;

    this.prepareQuestion(staticQuestions, 'static');
    this.prepareQuestion(audibleQuestions, 'audible');
    this.prepareQuestion(flashQuestions, 'flash');
    if (this.staticQuestions.length > 0) {
      this.activeTab = 'static';
      this.chunks = [];
      from(this.staticQuestions).pipe(bufferCount(this.chunkNumber)).subscribe(chunk => this.chunks.push(chunk));
    } else if (this.audibleQuestions.length > 0) {
      this.activeTab = 'audible';
    } else if (this.flashQuestions.length > 0) {
      this.activeTab = 'flash';
    }
  }

  prepareQuestion(questions, category) {
    let sno = 1;
    for (const quest of questions) {
      const record: Question = {
        sno,
        question: quest,
        answer: eval(quest),
        inputedAnswer: '',
        isCorrect: undefined,
        isAnswered: undefined
      };
      if (category === 'static') {
        this.staticQuestions.push(record);
      } else if (category === 'audible') {
        this.audibleQuestions.push(record);
      } else if (category === 'flash') {
        this.flashQuestions.push(record);
      }
      sno++;
    }
  }

  populateVoiceList() {
    const voices = this.synth.getVoices();

    for (const voice of voices) {
      const option = {
        name: voice.name,
        lang: voice.lang,
        default: false
      };

      if (voice.default) {
        option.default = true;
      }
      this.voices.push(option);
    }
  }

  checkStaticQuestions() {
    this.isCheckClicked = true;
    this.countPassed = 0;
    let isAllPassed = true;
    for (const item of this.staticQuestions) {
      if (item.inputedAnswer && item.inputedAnswer == item.answer) {
        this.countPassed++;
        item.isCorrect = true;
      }
      if (item.inputedAnswer && item.inputedAnswer != item.answer) {
        item.isCorrect = false;
        isAllPassed = false;
      }
    }
    this.myScore.static = this.countPassed;
    this.saveProgress();
    $('#staticResult').modal('show');
  }

  startAudible() {
    let question = this.audibleQuestions[this.currentPosition].question;
    question = question.replace(/\+/g, 'plus').replace(/-/g, 'minus').replace(/\*/g, 'times').replace(/\//g, 'divide by');
    const utterThis = new SpeechSynthesisUtterance(question);
    utterThis.rate = +this.speakingRate;
    this.synth.speak(utterThis);
    this.audibleAnswer.nativeElement.focus();
  }

  startFlash() {
    const question = this.flashQuestions[this.currentPosition].question;
    const flashCurrentQuestion = question.split(' ');
    let startIndex = 0;
    this.flashIsStart = true;
    this.flashCurrentCharacter = flashCurrentQuestion[0];
    this.displayInterval = setInterval(() => {
      startIndex++;
      this.flashCurrentCharacter = flashCurrentQuestion[startIndex];
      if (startIndex === flashCurrentQuestion.length) {
        this.flashIsStart = false;
        clearInterval(this.displayInterval);
        this.flashAnswer.nativeElement.focus();
      }
    }, this.flashRate);
  }

  nextAudible(answer) {
    this.isWrong = false;
    if (this.currentPosition === this.audibleQuestions.length) {
      this.progress('audible');
    } else {
      // tslint:disable-next-line: triple-equals
      if (this.audibleQuestions[this.currentPosition].answer != answer) {
        this.isWrong = true;
      } else {
        this.audibleAnswer.nativeElement.value = '';
        this.audibleQuestions[this.currentPosition].isCorrect = true;

        // increase score and save
        this.myScore.audible++;
        this.saveProgress();

        $('#audibleResult').modal('show');
        this.currentPosition++;
      }
    }
  }

  nextFlash(answer) {
    this.isWrong = false;
    if (this.currentPosition === this.flashQuestions.length) {
      this.progress('flash');
    } else {
      // tslint:disable-next-line: triple-equals
      if (this.flashQuestions[this.currentPosition].answer != answer) {
        this.isWrong = true;
      } else {
        this.flashAnswer.nativeElement.value = '';
        this.flashQuestions[this.currentPosition].isCorrect = true;

        // increase score and save
        this.myScore.flash++;
        this.saveProgress();

        $('#flashResult').modal('show');
        this.currentPosition++;
      }
    }
  }

  progress(stage) {
    let isAllPassed = true;
    let questions;
    if (stage === 'static') {
      questions = this.staticQuestions;
    } else if (stage === 'audible') {
      questions = this.audibleQuestions;
    } else if (stage === 'flash') {
      questions = this.flashQuestions;
    }

    for (const question of questions) {
      if (!question.isCorrect) {
        isAllPassed = false;
        alert('You haven\'t passed all questions');
        break;
      }
    }

    if (isAllPassed) {
      this.currentPosition = 0;
      if (stage === 'static') {
        $('#staticResult').modal('hide');
        if (this.audibleQuestions.length > 0) {
          this.activeTab = 'audible';
        } else if (this.flashQuestions.length > 0) {
          this.activeTab = 'flash';
        } else {
          this.handleProgress();
        }
      } else if (stage === 'audible') {
        $('#audibleResult').modal('hide');
        if (this.flashQuestions.length > 0) {
          this.activeTab = 'flash';
        } else {
          this.handleProgress();
        }
      } else if (stage === 'flash') {
        $('#flashResult').modal('hide');
        this.handleProgress();
      }
    }
  }

  handleProgress() {
    this.saveProgress();
    this.resetAllVariables();
    this.message = 'You are done. Please wait for match to end';
    $('#classEndedModal').modal('show');
  }

  close(category) {
    $('#' + category + 'Result').modal('hide');
    let questions;
    if (category === 'static') {
      if (this.countPassed == this.staticQuestions.length) {
        this.progress(category);
      }
    } else {
      if (category === 'audible') {
        questions = this.audibleQuestions;
      } else if (category === 'flash') {
        questions = this.flashQuestions;
      }
      if (this.currentPosition === questions.length) {
        this.progress(category);
      }
    }
  }

  closeClassEnded() {
    $('.modal').modal('hide');
    $('#classEndedModal').modal('hide');
    this.router.navigate(['/dashboard/matchroom']);
  }

  resetAllVariables() {
    this.questionResponse = [];
    this.chunks = [];
    this.flashQuestions = [];
    this.staticQuestions = [];
    this.audibleQuestions = [];
    this.currentPosition = 0;
    this.countPassed = 0;
    this.isCheckClicked = false;
  }

  toggleAbacus(status) {
    this.showAbacus = status;
  }

  saveProgress() {
    const score = this.myScore.static + this.myScore.audible + this.myScore.flash;
    this.socket.emit('save_progress', { challenge_id: this.challengeID, score });
  }

  endChallenge() {
    if (confirm('Are you sure to end challenge?')) {
      this.socket.emit('finish_match', this.challengeID);
    }
  }

  getScore(student) {
    let score = 'Nil';
    for (const record of this.challengeScore) {
      if (record._id === student) {
        score = record.score;
      }
    }
    return score;
  }

}
