import { Component, OnInit } from '@angular/core';
import { lang } from 'src/lang/lang';
@Component({
  selector: 'app-invite-friend',
  templateUrl: './invite-friend.component.html',
  styleUrls: ['./invite-friend.component.css']
})
export class InviteFriendComponent implements OnInit {
  lang = lang;
  locale = 'es';

  constructor() { }

  ngOnInit() {
    var ln = localStorage.getItem('locale');
    if(ln != null) {
      this.locale = ln;
    }
  }

}
