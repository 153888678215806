import { Component, OnInit } from '@angular/core';
import { UtilService } from './shared/services/util.service';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { filter } from 'rxjs/operators';
import { lang } from 'src/lang/lang';

declare let window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'abacus-project';
  errorMessage;
  successMessage;
  isLoading;
  lang = lang;
  locale = 'es';
  constructor(private utilService: UtilService,
              private router: Router) { }

  ngOnInit(): void {
    var ln = localStorage.getItem('locale');
    if(ln != null) {
      this.locale = ln;
    }


    document.addEventListener('backbutton', (evt) => {
      this.utilService.showMessage('Back button diabled, please using the menu');
    }, false);

    this.utilService.errorMessage.subscribe(msg => {
      this.errorMessage = msg;
      setInterval(() => {
        this.errorMessage = undefined;
      }, 5000);
    });

    this.utilService.successMessage.subscribe(msg => {
      this.successMessage = msg;
      setInterval(() => {
        this.errorMessage = undefined;
      }, 5000);
    });

    this.isLoading = this.utilService.appIsLoading;

    this.router.events
      .pipe(
        filter(
          event =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError,
        ),
      )
      .subscribe(event => {
        if (event instanceof NavigationStart) {
          this.utilService.appIsLoading.next(true);
          return;
        }

        this.utilService.appIsLoading.next(false);
      });
  }
}
