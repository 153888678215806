import { KeyValue } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { variable } from '@angular/compiler/src/output/output_ast';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { APIResponse } from 'src/app/shared/models/api-response';
import { Student } from 'src/app/shared/models/student';
import { Level } from 'src/app/shared/models/student_level';
import { HandleRequestService } from 'src/app/shared/services/handle-request.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { lang } from 'src/lang/lang';
declare var $;

@Component({
  selector: 'app-homework',
  templateUrl: './homework.component.html',
  styleUrls: ['./homework.component.css']
})
export class HomeworkComponent implements OnInit, OnDestroy {
  levelInfo: Level;
  student: Student;
  studentProgress: any;
  noProgress = true;
  lessons: any = {};
  selectedLevel;
  currentLevel;
  allHomeworks: any[] = [];
  groupedHomeworks: any = {};
  progresses = {};
  lang = lang;
  locale = 'es';

  constructor(private route: ActivatedRoute,
              private handleRequest: HandleRequestService,
              private utilService: UtilService,
              private router: Router) { }

  ngOnInit() {
    var ln = localStorage.getItem('locale');
    if(ln != null) {
      this.locale = ln;
    }
    this.route.data.subscribe((data: Data) => {
      if (data.hasOwnProperty('profile')) {
        const response = data.profile as APIResponse;
        if (!response.error) {
          this.student = response.data;
        }
      }

      if (data.hasOwnProperty('levels')) {
        const response = data.levels as APIResponse;
        if (!response.error) {
          this.levelInfo = response.data;
        }
      }

      if (data.hasOwnProperty('progress')) {
        const response = data.progress as APIResponse;
        if (!response.error) {
          this.studentProgress = response.data;
          // tslint:disable-next-line: no-unused-expression
          this.noProgress = Object.keys(this.studentProgress).length === 0 && this.studentProgress.constructor === Object;

          for(var item of this.studentProgress.level_points) {
            this.progresses[item.level] = item;
          }

          this.checkCurrentLevel();
        }
      }
    });
  }

  checkCurrentLevel() {
    const levels = this.levelInfo.levels;
    let currentIndex;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < levels.length; i++) {
      if (!this.noProgress && levels[i]._id === this.studentProgress.level)  {
        currentIndex = i;
      }
      if (!isNaN(currentIndex)) {
        if (currentIndex > i) {
          levels[i].status = 'done';
          var progress = this.progresses[levels[i]._id];
          if(progress) {
            if(progress.points == -1) {
              levels[i].points = 100;
            } else {
              levels[i].points = progress.points;
            }

            levels[i].date = this.convertMillisecondsToDate(progress.date);

          } else {
            levels[i].points = 100;
            levels[i].date = "12 Jan 22";
          }
        } else if (currentIndex < i) {
          levels[i].status = 'pending';
        } else  {
          levels[i].status = 'current';
          var progress = this.progresses[levels[i]._id];
          if(progress) {
            if(progress.points == -1) {
              levels[i].points = 100;
            } else {
              levels[i].points = progress.points;
            }
  
            levels[i].date = this.convertMillisecondsToDate(progress.date);
  
          } else {
            levels[i].points = 100;
            levels[i].date = "12 Jan 22";
          }
        }
      } else {
        levels[i].status = 'done';
        var progress = this.progresses[levels[i]._id];
        if(progress) {
          if(progress.points == -1) {
            levels[i].points = 100;
          } else {
            levels[i].points = progress.points;
          }

          levels[i].date = this.convertMillisecondsToDate(progress.date);

        } else {
          levels[i].points = 100;
          levels[i].date = "Jan 12, 2022";
        }
      }
    }
  }

  ngOnDestroy() {
    $('#lessons').modal('hide');
  }

  getAvatar() {
    if (typeof  this.student.avatar != "object") {
      return this.student.avatar;
    } else {
      return 'assets/images/avatar.png';
    }
  }

  navigateToLessons(id) {
    const level = this.levelInfo.levels.find(x => x._id === id);
    if (level.status !== 'pending') {
      this.utilService.appIsLoading.next(true);
      this.handleRequest.get(`/levels/${level._id}/lessons`).then(
        (res: APIResponse) => {
          this.utilService.appIsLoading.next(false);
          if (!res.error) {
            this.lessons = res.data.lessons;
            this.selectedLevel = level._id;
            this.currentLevel = level;
            this.extractLessons();
            this.checkPosition();
            this.groupedLessons();
            // $('#lessons').modal('show');

            this.handleRequest.get(`/progress/${level._id}`).then(
              (res: APIResponse) => {
                var lessonProgresses = res.data.progresses;
                var parsedProgresses = {};
                for(const progress in lessonProgresses) {
                  if(lessonProgresses[progress].points == -1) {
                    lessonProgresses[progress].points = 100;
                  }

                  if(!parsedProgresses[lessonProgresses[progress].lesson])
                    parsedProgresses[lessonProgresses[progress].lesson] = {};

                  if(!parsedProgresses[lessonProgresses[progress].lesson][lessonProgresses[progress].day])
                    parsedProgresses[lessonProgresses[progress].lesson][lessonProgresses[progress].day] = {};


                  parsedProgresses[lessonProgresses[progress].lesson][lessonProgresses[progress].day][lessonProgresses[progress].type]
                  = lessonProgresses[progress].points;
                }

                for(const group in this.groupedHomeworks) {

                  let groupSumPoints = 0;
                  let groupCountPoints = 0;

                  for(const day in this.groupedHomeworks[group].rooms) {

                    let daySumPoints = 0;
                    let dayCountPoints = 0;                                        
                    for(const item in this.groupedHomeworks[group].rooms[day].items) {

                      let points = 100;

                      let classItem = this.groupedHomeworks[group].rooms[day].items[item];

                      if(classItem.item._id in parsedProgresses 
                        && classItem.day in parsedProgresses[classItem.item._id] 
                        && classItem.type in parsedProgresses[classItem.item._id][classItem.day]) {
                          points = parsedProgresses[classItem.item._id][classItem.day][classItem.type];
                        }

                      if(classItem.status == "past") {
                        classItem.points = points;
                        dayCountPoints++;
                        daySumPoints+=points;
                        groupCountPoints++;
                        groupSumPoints+=points;
                      }
                      
                    }

                    if(dayCountPoints > 0) {
                      this.groupedHomeworks[group].rooms[day].points = Math.round(daySumPoints/dayCountPoints);
                    }

                  }


                  if(groupCountPoints > 0) {
                    this.groupedHomeworks[group].points = Math.round(groupSumPoints/groupCountPoints);
                  }
                }

                

              }
            )

          } else {
            alert('Request failed, please try again');
          }
        }, (errorResponse: HttpErrorResponse) => {
          this.utilService.appIsLoading.next(false);
          const message = errorResponse.error.message;
          alert(message);
        }
      );

      
    }
  }

  backToLevel() {
    this.allHomeworks = [];
  }

  navigateToHomework(homework) {
    if (homework.status !== 'pending') {
      this.router.navigate([`/dashboard/homework/${this.selectedLevel}/${homework.item._id}`], { queryParams: { lesson: homework.item.group, day: homework.day, type: homework.type } });
    }
  }

  keys(): Array<string> {
    return Object.keys(this.lessons);
  }

  extractLessons() {
    this.allHomeworks = [];
    for (const les in this.lessons) {
      if (les) {
        for (const lesson of this.lessons[les]) {
          if (lesson.homework.length > 0) {
            this.allHomeworks.push(lesson);
          }
        }
      }
    }
  }

  toggle(lesson) {
    $('.collapse').collapse('show');
  }

  checkPosition() {
    let count = 0;
    let currentIndex;
    // tslint:disable-next-line: forin
    for (const meta in this.lessons) {
      for (const lesson of this.lessons[meta]) {
        if (lesson._id === this.studentProgress.lesson) {
          currentIndex = count;
        }
        if (!isNaN(currentIndex)) {
          if (count < currentIndex) {
            lesson.status = 'past';
          } else if (count > currentIndex) {
            lesson.status = 'pending';
          } else {
            lesson.status = 'current';
          }
        } else {
          lesson.status = 'past';
        }
        count++;
      }
    }
  }

  groupedLessons() {
    let grouped = this.groupBy(this.allHomeworks, 'group');

    this.groupedHomeworks = {};

    for(const i in grouped) {
      if(i) {
        this.groupedHomeworks[i] = {};
        let totalCount = 0;
        let completedCount = 0;
        this.groupedHomeworks[i]['rooms'] = [];
        this.groupedHomeworks[i]['totalCount'] = totalCount;
        this.groupedHomeworks[i]['completedCount'] = 0;
        this.groupedHomeworks[i]['currentDay'] = 0;
        var isCurrent = false;


        for(var j = 0; j < 6; j++) {
          this.groupedHomeworks[i]['rooms'][j] = {'items': []};
        }

        for(var item of grouped[i]) {
          for(var j = 0; j < 6; j++) {
            for(const homeItem of item.homework) {
              if(homeItem.day == j + 1) {
                var data = {};
                data['type'] = homeItem.type;
                data['value'] = homeItem.value;
                data['day'] = homeItem.day;
                data['item'] = item;
                data['status'] = item.status;

                if(item.group.toString() == this.studentProgress.group && item.level == this.studentProgress.level) {
                  if(item._id === this.studentProgress.lesson && data['day'] === this.studentProgress.day && data['type'] === this.studentProgress.type) {
                    isCurrent = true;
                    this.groupedHomeworks[i]['currentDay'] = j;
                    data['status'] = 'current';
                  } else {
                    if(homeItem.day < this.studentProgress.day) {
                      data['status'] = 'past';
                    } else if(homeItem.day > this.studentProgress.day) {
                        data['status'] = 'pending';
                    } else {
                      if(data['status'] === 'current') {
                        if(data['type'] == 'a' || data['type'] == 'f')
                          data['status'] = 'pending';
                        else
                          data['status'] = 'past';
                      }
                    }
                  } 
                }

                if(data['status'] === 'past') completedCount++;

                this.groupedHomeworks[i]['rooms'][j]['items'].push(data);
                totalCount ++;
              }
            }
          }
          
        }

        this.groupedHomeworks[i]['totalCount'] = totalCount;

        this.groupedHomeworks[i]['completedCount'] = completedCount;
        if(isCurrent) {
          this.groupedHomeworks[i]['status'] = 'current';
        } else {
          if(completedCount === totalCount) {
            this.groupedHomeworks[i]['status'] = 'past';
          } else {
            this.groupedHomeworks[i]['status'] = 'pending';
          }
        }

      }
    }

    console.log(this.groupedHomeworks);

  }

  groupBy(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  keyDescOrder = (a: KeyValue<number,object>, b: KeyValue<number,object>): number => {
    return a.key - b.key;
  }

  convertMillisecondsToDate(milliseconds) {
    const dateObject = new Date(milliseconds)

    const humanDateFormat = dateObject.toLocaleString("en-US", {day: "numeric", month: "short", year: "numeric"});

    return humanDateFormat;
  }
}
