import { Component, ElementRef, OnInit, Renderer, ViewEncapsulation, ViewChildren, QueryList, } from '@angular/core';
import { UtilService } from 'src/app/shared/services/util.service';
import { HandleRequestService } from 'src/app/shared/services/handle-request.service';
import { APIResponse } from 'src/app/shared/models/api-response';
import { ActivatedRoute } from '@angular/router';
import { Student } from 'src/app/shared/models/student';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-toddler6',
  templateUrl: './toddler6.component.html',
  styleUrls: ['./toddler6.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class Toddler6Component implements OnInit {
  @ViewChildren('canvas') canvasElements: QueryList<ElementRef<HTMLCanvasElement>>;
  canvases = [
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
  ];
  
  resultData: any[] = [];
  student: Student;
  isIOS = false;


  defaultData = [
    ['157', '192', '209', '123', '180', '123', '46'], // 1
    ['411', '609', '124', '364', '459', '746', '623'], // 2
    ['3496', '4935', '8874', '3776', '1718', '3384', '402','103', '140', '93', '341', '121', '216', '26'], // 3
    ['85', '29', '96', '31', '80'], // 4
    ['112', '834', '151', '384', '151','459', '779', '251', '568', '155'], // 5
    ['64', '48', '92', '36', '76', '24', '116', '2','9', '3', '6', '4', '11', '14'], // 6
    [], // 7
    [], // 8
    ['193', '107', '117', '133', '180', '168', '163'], // 9
    ['166', '430', '786', '915', '284', '2', '191'], // 10
    ['3295', '6687', '3588', '3171', '2778', '2538', '1580', '412','25', '14', '124', '132', '26', '312'], // 11
    ['32', '88', '39', '13', '58'], // 12
    ['692', '607', '610', '369', '151', '477', '211', '397', '124', '399'], // 13
    ['60', '92', '30', '58', '64', '96', '92', '5', '6', '8', '9', '7', '7', '5'], // 14
    [], // 15
    [], // 16
    ['225', '129', '213', '200', '152', '210', '175'], // 17
    ['415', '521', '760', '138', '334', '590', '619'], // 18
    ['2982', '2061', '6768', '4548', '1556', '306', '3584', '413','149', '123', '107', '121', '63', '42'], // 19
    ['21', '68', '60', '27', '97'], // 20
    ['786', '201', '122', '112', '652', '150', '155', '765', '702', '988'], // 21
    ['12', '15', '92', '54', '45', '78', '180', '9','2', '9', '3', '8', '12', '7'], // 22
    [], // 23
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'], // 24
    ['166', '144', '153', '116', '176', '149', '157'], // 25
    ['460', '534', '235', '338', '391', '821', '273'], // 26
    ['3870', '5715', '6656', '2555', '2756', '8559', '2534', '170','241', '110', '380', '130', '437', '134'], // 27
    ['30', '89', '15', '21', '20'], // 28
    ['543', '633', '544', '544', '749', '597', '769', '987', '588', '945'], // 29
    ['12', '288', '24', '96', '98', '96', '98', '8','6', '5', '7', '9', '5', '7'], // 30
    [], // 31
    [], // 32
    ['181', '183', '220', '135', '145', '137', '228'], // 33
    ['815', '281', '661', '117', '615', '614', '721'], // 34
    ['4158', '2172', '3168', '2552', '2622', '1086', '1524', '85','59', '45', '121', '133', '120', '111'], // 35
    ['49', '69', '98', '87', '69'], // 36
    ['676', '735', '385', '575', '855', '558', '766', '677', '686', '975'], // 37
    ['30', '148', '246', '340', '174', '280', '36', '4','28', '15', '12', '9', '5', '4'], // 38
    [], // 39
    [], // 40
    ['155', '146','208', '259', '215', '172', '118'], // 41
    ['587', '761','218', '288', '138', '510', '702'], // 42
    ['12970', '28374', '17912', '29380', '18940', '13860', '8496', '1209','1560', '2943', '1351', '1151', '1423', '789'], // 43
    ['60', '14', '10', '19', '51'], // 44
    ['596', '535', '739', '557', '787', '775', '895', '462', '555', '956'], // 45
    ['172', '490', '384', '135', '595', '252', '267', '13','7', '22', '7', '8', '11', '39'], // 46
    [], // 47
    ['16', '19', '18', '10', '13', '14', '9', '21'], // 48
    ['173', '226', '297', '184', '202', '167', '159'], // 49
    ['810', '152', '369', '980', '524', '117', '427'], // 50
    ['21774', '42325', '34974', '24934', '14048', '47124', '5610', '607', '2364', '1507', '1315', '1348', '745', '754'], // 51
    ['50', '23', '84', '76', '79'], // 52
    ['445', '557', '413', '420', '656', '725', '321', '423', '758', '678'], // 53
    ['147', '375', '266', '192', '368', '141', '704', '24', '8', '21', '12', '11', '9', '33'], // 54
    [], // 55
    [], // 56
    ['137', '198', '274', '182', '169', '184', '110'], // 57
    ['280', '738', '920', '384', '451', '259', '797'], // 58
    ['18864', '38946', '29981', '78992', '46435', '14916', '28445', '523', '141', '136', '287', '360', '458', '365'], // 59
    ['12', '12', '4', '10', '79'], // 60
    ['211', '413', '539', '777', '531', '213', '211', '588', '431', '231'], // 61
    ['280', '344', '648', '180', '184', '144', '154', '9', '32', '9', '7', '8', '7', '4'], // 62
    [], // 63
    ['3', '8', '6', '6', '5', '4'], // 64
    ['196', '362', '181', '280', '287', '104', '134',], // 65
    ['320', '213', '812', '885', '394', '126', '996',], // 66
    ['21462', '47889', '58891', '19768', '9408', '54971', '12815', '412', '571', '956', '714', '129', '258', '357'], // 67
    ['81', '92', '87', '39', '94'], // 68
    ['214', '659', '444', '535', '443', '478', '698', '144', '412', '956'], // 69
    ['138', '468', '392', '222', '483', '50', '132', '12', '15', '16', '19', '13', '6', '22'], // 70
    [], // 71
    [
      '0', '0', '0', '0', '0', '1', '1', '1', '1', '1', '1', '1',
      '1', '1', '1', '1', '1', '0', '0', '0', '0', '0', '0', '0',
      '0', '0', '0', '1', '1', '1', '1', '1', '1', '1', '0', '0',
      '0', '0', '0', '0', '0', '0', '1', '1', '1', '1', '1', '1',
      '1', '1', '1', '1', '1', '1', '0', '0', '0', '0', '0', '0',
      '0', '0', '0', '1', '1', '1', '1', '1', '1', '0', '0', '0',
      '0', '0', '1', '1', '1', '1', '1', '1', '1', '1', '1', '0',
    ], // 72
    ['184', '156', '228', '176', '195', '185', '160'], // 73
    ['270', '279', '269', '118', '229', '714', '439'], // 74
    ['82809', '33948', '45125', '25792', '18384', '44748', '33060', '4213', '1562', '623', '253', '218', '1910', '301'], // 75
    ['31', '55', '45', '25', '66'], // 76
    ['321', '688', '450', '859', '141', '645', '488', '214', '679', '465'], // 77
    ['168', '378', '882', '294', '399', '232', '288', '18', '43', '13', '9', '8', '4', '9'], // 78
    [], // 79
  ];
  type = 'toddler6';

  constructor(
    private utilService: UtilService,
    private httpService: HandleRequestService,
    private route: ActivatedRoute,
    private elRef: ElementRef,
    private toastr: ToastrService,
    private renderer: Renderer
  ) { }

  ngOnInit() {
    if (/iPad|iPhone|iPod/.test(window.navigator.userAgent)) {
      this.isIOS = true;
    }

    for (var i = 0; i < 130; i++) {
      this.resultData.push({
        values: [],
        times: '',
        count: 0,
        correctAnswers: 10,
        resultIsIncorrect: [false,false,false,false,false,false,false,false,false,false],
        finished: false
      })
    }
    this.route.data.subscribe(x => {
      if (x.hasOwnProperty('profile')) {
        const response = x.profile as APIResponse;
        this.student = response.data as Student;
      }

      this.httpService.post('/smartpdf/get', {
        type: this.type,
        user_id: this.student._id,
      }).then(
        (res: APIResponse) => {
          if (res.data.smartpdf) {
            this.resultData = res.data.smartpdf.data.result;
          }
          this.canvases.forEach((canvas, index) => {
            if (!canvas.context) {
              canvas.context = this.canvasElements.toArray()[index].nativeElement.getContext('2d');
            }
          });
          this.loadDrawing();
        }, () => {
         
        }
      );

      
    });


  }

  startDrawing(e: TouchEvent, index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = true;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const event = e.touches[0];
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.beginPath();
    canvas.context.moveTo(x, y);
  }

  draw(e: TouchEvent, index: number): void {
    const canvas = this.canvases[index];
    if (!canvas.drawing) return;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const event = e.touches[0];
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.lineTo(x, y);
    canvas.context.stroke();
  }

  stopDrawing(index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = false;
    canvas.context.closePath();
  }

  startDrawing1(event: MouseEvent, index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = true;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.beginPath();
    canvas.context.moveTo(x, y);
  }

  draw1(event: MouseEvent, index: number): void {
    const canvas = this.canvases[index];
    if (!canvas.drawing) return;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.lineTo(x, y);
    canvas.context.stroke();
  }

  stopDrawing1(index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = false;
    canvas.context.closePath();
  }

  saveDrawing(index: number): void {
    this.toastr.info('Saving...');
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const dataURL = canvasElement.toDataURL();
    this.httpService.post('/smartcanvas/update', {
      type: this.type,
      user_id: this.student._id,
      serial: index,
      data: dataURL
    }).then(
      (res: APIResponse) => {
        this.toastr.clear();
        this.toastr.success('Successfully Saved');
      }, () => {
      }
    );
  }

  async loadDrawing() {
    for (var index in this.canvases) {
      this.httpService.post('/smartcanvas/get', {
        type: this.type,
        user_id: this.student._id,
        serial: index
      }).then(
        (res: APIResponse) => {
          if (res.data.smartcanvas) {
            const img = new Image();
            img.onload = () => {
            const canvasElement = this.canvasElements.toArray()[index].nativeElement;
              const context = canvasElement.getContext('2d');
              context.clearRect(0, 0, canvasElement.width, canvasElement.height);
              context.drawImage(img, 0, 0);
            };
            img.src = res.data.smartcanvas.data;
          }
        }, () => {
         
        }
      );

      await this.delay(2000);
    }
    
  }

  backToSavedImage(index: number) {
    this.httpService.post('/smartcanvas/get', {
      type: this.type,
      user_id: this.student._id,
      serial: index
    }).then(
      (res: APIResponse) => {
        if (res.data.smartcanvas) {
          const img = new Image();
          img.onload = () => {
          const canvasElement = this.canvasElements.toArray()[index].nativeElement;
            const context = canvasElement.getContext('2d');
            context.clearRect(0, 0, canvasElement.width, canvasElement.height);
            context.drawImage(img, 0, 0);
          };
          img.src = res.data.smartcanvas.data;
        }
      }, () => {
       
      }
    );
  }

  clearCanvas(index: number): void {
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const context = canvasElement.getContext('2d');
    context.clearRect(0, 0, canvasElement.width, canvasElement.height);
  }

  canvasPropertiesChanges(): void {
    this.canvases.forEach(canvas => {
      if (!canvas.isEraser) {
        canvas.context.strokeStyle = canvas.brushColor;
      }
      canvas.context.lineWidth = canvas.brushSize;
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  quastionConfirm(index) {
    this.resultData[index].finished = true;
    if (this.resultData[index].count < 2) {
      this.resultData[index].count++;
      let allCorrect = true;
      for (let i in this.defaultData[index]) {
        if (this.defaultData[index][i] != this.resultData[index].values[i]) {
          allCorrect = false;
        }
      }
      if (!allCorrect && this.resultData[index].count == 1) {
        this.toastr.error('Corrige el resultado en rojo y vuelve a picar. Solo tienes una oportunidad de corregir.');
      }
    }
    this.saveData();
  }
  quastionChange(index) {
    this.resultData[index].finished = false;
  }

  quastionConfirm1(index) {
    this.resultData[index].finished = true;
    this.saveData();
  }

  clickedNumbers(i, j) {
    if (this.resultData[i].finished && this.resultData[i].count >= 2) {
      return;
    }
    this.resultData[i].finished = false;
    if (this.resultData[i].values[j] == '1') {
      this.resultData[i].values[j] = '0';
    } else {
      this.resultData[i].values[j] = '1';
    }
  }

  correctAnswerCheck(i, j) {
    if (!this.resultData[i].resultIsIncorrect[j] && this.resultData[i].finished){
      if (document.activeElement && document.activeElement !== document.body) {
        this.renderer.invokeElementMethod(
          this.elRef.nativeElement.ownerDocument.activeElement, 'blur');
      }
      this.resultData[i].resultIsIncorrect[j] = true;
      this.resultData[i].correctAnswers--;
      this.saveData();
    }
  }

  saveData() {
    this.httpService.post('/smartpdf/update', {
      type: this.type,
      user_id: this.student._id,
      data: {
        result: this.resultData
      }
    }).then(
      (res: APIResponse) => {
      }, () => {
      }
    );
  }
}
