import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paypal-details',
  templateUrl: './paypal-details.component.html',
  styleUrls: ['./paypal-details.component.css']
})
export class PaypalDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
