import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Student } from 'src/app/shared/models/student';
import { APIResponse } from 'src/app/shared/models/api-response';
import { HandleRequestService } from 'src/app/shared/services/handle-request.service';
import { lang } from 'src/lang/lang';
declare var $;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  student: Student;
  studentProgress: any;
  bourdonTests: any;
  lang = lang;
  locale = 'es';
  changing = false;
  constructor(
    private httpService: HandleRequestService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    var ln = localStorage.getItem('locale');
    if(ln != null) {
      this.locale = ln;
    }
    this.route.data.subscribe(x => {
      if (x.hasOwnProperty('profile')) {
        const response = x.profile as APIResponse;
        this.student = response.data as Student;
      }

      if (x.hasOwnProperty('progress')) {
        const response = x.progress as APIResponse;
        if (!response.error) {
          this.studentProgress = response.data;
        }
      }

      this.httpService.get('/account/bourdon/all',).then(
        (res: APIResponse) => {
          this.bourdonTests = res.data.data;
        }, () => {
        }
      );

    });
  }

  getAvatar() {
    if (typeof  this.student.avatar != "object") {
      return this.student.avatar;
    } else {
      return 'assets/images/avatar.png';
    }
  }

  changeAvatar() {
    this.router.navigate(['/dashboard/avatar']);
  }

  askChangeGender() {
    if (this.changing) {
      return;
    }
    $('#confirm-change-gender').modal();
  }

  confirmChangeGender() {
    this.changing = true;
    $('#confirm-change-gender').modal('hide');
    this.httpService.post('/account/gender/update', {gender: this.student.gender}).then(
      (res: APIResponse) => {
        this.httpService.get('/account').then(
          (res: APIResponse) => {
            this.changing = false;
            this.student = res.data as Student;
          }, () => {
            this.changing = false;
          }
        );
      }, () => {
        this.changing = false;
      }
    );
  }

}
