import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Student } from 'src/app/shared/models/student';
import { APIResponse } from 'src/app/shared/models/api-response';
import { lang } from 'src/lang/lang';

@Component({
  selector: 'app-profilecard',
  templateUrl: './profilecard.component.html',
  styleUrls: ['./profilecard.component.css']
})
export class ProfilecardComponent implements OnInit {
  @Input() student: Student;
  @Input() studentProgress: any;
  @Input() link: string;
  lang = lang;
  locale = 'es';
  progress = [];
  backprogress = [];

  constructor(private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    var ln = localStorage.getItem('locale');
    if(ln != null) {
      this.locale = ln;
    }
    let lastLesson = 8;
    if (this.student.age_bracket == 'TODDLER') {
      lastLesson = 10;
    }

    for (let i=0; i < lastLesson; i++) {
      let status = 'pending';
      if (this.student.level > i + 1) {
        status = 'done';
      } else if (this.student.level == i + 1) {
        status = 'current';
      }
      this.progress.push(status);
    }
    
    for (let i=0; i < lastLesson-1; i++) {
      let status = '';

      if (i < this.student.level - 1) {
        status = 'active';
      }

      this.backprogress.push(status);
    }
  }

  getAvatar() {
    
    if (typeof  this.student.avatar != "object") {
      return this.student.avatar;
    } else {
      return 'assets/images/avatar.png';
    }
  }
}
