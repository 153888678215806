import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desktop-nav-icon',
  templateUrl: './desktop-nav-icon.component.html',
  styleUrls: ['./desktop-nav-icon.component.css']
})
export class DesktopNavIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
