import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { APIResponse } from 'src/app/shared/models/api-response';
import { Student } from 'src/app/shared/models/student';
import { Level } from 'src/app/shared/models/student_level';
import { STUDENT_AUTH } from 'src/app/shared/services/app-config';
import { UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';
import io from 'socket.io-client';
import { Subject, Subscription } from 'rxjs';
import { Question } from 'src/app/shared/models/question';
import { lang } from 'src/lang/lang';
declare var $: any;

@Component({
  selector: 'app-matchroom',
  templateUrl: './matchroom.component.html',
  styleUrls: ['./matchroom.component.css']
})
export class MatchroomComponent implements OnInit, OnDestroy {
  levelInfo: Level;
  student: Student;
  studentProgress: any;
  noProgress = false;
  socket: any;
  activeUsers: Student[] = [];
  selectedUsers: any[] = [];
  acceptedUsers: any[] = [];
  challenger;
  challenged;
  challengeID;
  isChallengeCreated = false;
  isChallengeAccepted;
  challengeAccepted: Subject<{status: boolean, isCreator: boolean, challenge: string, user?: any}> = new Subject();
  subscription: Subscription;
  challengeStarts = 5;
  challengeQuestions: Question[] = [];
  chunks = [];
  chunkNumber = 5;
  isCreator = false;
  isCheckClicked = false;
  countPassed = 0;
  finalScore: {
    your: number,
    defendant: number
  } = {
    your: 0,
    defendant: 0
  };
  isChallengeActionDone = false;
  matchQuestion: {
    static: number,
    audible: number,
    flash: number
  } = {
    static: 10,
    audible: 10,
    flash: 10
  };
  matchError;
  myAccepted = false;
  lang = lang;
  locale = 'es';
  constructor(private route: ActivatedRoute,
              private utilService: UtilService,
              private router: Router) {
    this.utilService.retrieveLocalStorage(STUDENT_AUTH).subscribe(
      (stud: Student) => {
        this.socket = io(`${environment.domain}/matchroom`, {
          query: {
            token: stud.token,
          },
        });
        this.socketEvents();
      }
    );
  }

  ngOnInit() {
    var ln = localStorage.getItem('locale');
    if(ln != null) {
      this.locale = ln;
    }
    $('.modal').modal('hide');
    this.route.data.subscribe((data: Data) => {
      if (data.hasOwnProperty('profile')) {
        const response = data.profile as APIResponse;
        if (!response.error) {
          this.student = response.data;
        }
      }

      if (data.hasOwnProperty('levels')) {
        const response = data.levels as APIResponse;
        if (!response.error) {
          this.levelInfo = response.data;
        }
      }

      if (data.hasOwnProperty('progress')) {
        const response = data.progress as APIResponse;
        if (!response.error) {
          this.studentProgress = response.data;
        }
      }
    });

    this.subscription = this.challengeAccepted.subscribe(response => {
      this.isChallengeAccepted = response.status;
      if (response.status) {
        if (response.user) {
          this.acceptedUsers.push(response.user);
        }
        if (this.acceptedUsers.length === this.selectedUsers.length) {
          const interval = setInterval(() => {
            this.challengeStarts--;
            if (this.challengeStarts === 0) {
              clearInterval(interval);
              if (response.isCreator) {
                this.socket.emit('start_match', response.challenge);
              }
            }
          }, 1000);
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.socket.close();
    this.resetAllModals();
  }

  socketEvents() {
    this.socket.on('connect', () => {
      this.socket.emit('active_users');
    });

    this.socket.on('user_joined', (user) => {
      this.socket.emit('active_users');
    });

    this.socket.on('user_left', (user) => {
      this.socket.emit('active_users');
    });

    this.socket.on('disconnect', () => {
      this.resetAllModals();
    });

    this.socket.on('active_users', (users) => {
      this.activeUsers = users;
      this.activeUsers.forEach(x => x.isSelected = false);
    });

    this.socket.on('new_challenge', (challenge) => {
      this.challenger = this.activeUsers.find(x => x._id === challenge.challenger_id);
      this.challengeID = challenge.challenge_id;
      $('#challenged').modal('show');
      $('#grandResult').modal('hide');
    });

    this.socket.on('challenge_accepted', (challenge) => {
      this.challengeAccepted.next({status: true, isCreator: true, challenge: challenge.challenge_id, user: challenge.defender});
      this.challengeID = challenge.challenge_id;
      this.isCreator = true;
    });

    this.socket.on('challenge_declined', (challenge) => {
      this.challengeAccepted.next({status: false, isCreator: false, challenge: challenge.challenge_id, user: challenge.defender});
    });

    this.socket.on('match_started', (challenge) => {
      if (this.myAccepted) {
        $('#challenge').modal('hide');
        $('#challenged').modal('hide');
        this.router.navigate([`/dashboard/match/${challenge.challenge_id}`]);
      } else {
        this.isChallengeActionDone = true;
        this.isChallengeAccepted = false;
      }
    });

    this.socket.on('challenge_created', (challenge) => {
      $('#grandResult').modal('hide');
      this.isChallengeCreated = true;
      this.myAccepted = true;
    });

    this.socket.on('accepted_challenge', (challenge) => {
      this.challengeAccepted.next({status: true, isCreator: false, challenge: challenge.challenge_id});
    });

    this.socket.on('declined_challenge', (challenge) => {
      this.challengeAccepted.next({status: false, isCreator: true, challenge: challenge.challenge_id});
    });

    // this.socket.on('message', (message) => console.log(message));

    this.socket.on('f_error', (error) => {
      alert(error);
      this.resetAllModals();
    });

    this.socket.on('error', (error) => {
      alert(error);
      this.resetAllModals();
    });
  }

  getAvatar() {
    
    if (typeof  this.student.avatar != "object") {
      return this.student.avatar;
    } else {
      return 'assets/images/avatar.png';
    }
  }

  selectUser(user_id) {
    const user = this.activeUsers.find(x => x._id === user_id);
    user.isSelected = true;
    if (!this.selectedUsers.find(x => x === user_id)) {
      this.selectedUsers.push(user._id);
    }
  }

  createChallenge() {
    if (this.selectedUsers.length === 0) {
      alert('Select users to challenge');
    } else {
      this.isChallengeCreated = false;
      this.isChallengeAccepted = undefined;
      this.isChallengeActionDone = false;
      $('#challenge').modal('show');
    }
  }

  activateChallenge() {
    if (this.matchQuestion.static === 0 && this.matchQuestion.audible === 0 && this.matchQuestion.flash === 0) {
      this.matchError = 'Kindly enter at least a category';
    } else if (this.matchQuestion.flash < 0 || this.matchQuestion.flash > 10 ||
      this.matchQuestion.audible < 0 || this.matchQuestion.audible > 10 ||
      this.matchQuestion.static < 0 || this.matchQuestion.static > 10) {
      alert('Error detected, must not be below 0 or greater than 10');
    } else {
      this.socket.emit('challenge_user', {
        users: this.selectedUsers,
        f: this.matchQuestion.flash,
        a: this.matchQuestion.audible,
        s: this.matchQuestion.static
      });
    }
  }

  acceptChallenge() {
    this.isChallengeActionDone = true;
    this.myAccepted = true;
    this.socket.emit('accept_challenge', this.challengeID);
  }

  rejectChallenge() {
    if (confirm('Are you sure to decline challenge')) {
      this.isChallengeActionDone = true;
      this.socket.emit('decline_challenge', this.challengeID);
    }
  }

  resetAllModals() {
    $('#challenged').modal('hide');
    $('#challenge').modal('hide');
    this.isChallengeCreated = false;
    this.isCreator = false;
    this.challengeQuestions = [];
    this.chunks = [];
    this.isChallengeAccepted = undefined;
    this.challengeStarts = 5;
    this.isChallengeActionDone = false;
    this.selectedUsers = [];
    this.myAccepted = false;
  }

  checkChallengeAcceptance() {
    if (typeof this.isChallengeAccepted === 'undefined') {
      return 'none';
    } else {
      return this.isChallengeAccepted;
    }
  }

  startNow() {
    this.socket.emit('start_match', this.challengeID);
  }

}
