import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared.module';

import { HandleRequestService } from '../shared/services/handle-request.service';
import { UtilService } from '../shared/services/util.service';
import { MakeHttpRequest } from '../shared/services/http.service';
import { StudentAuthGuardService } from '../shared/services/auth/student-auth-guard.service';
import { StudentAuthInterceptor } from '../shared/services/auth/student-auth.interceptor';
import { StudentAuthService } from '../shared/services/auth/student-auth.service';
import { PaymentPlansResolver } from '../shared/resolvers/get-payment-plans-resolver.service';

import { HomeRoutingModule } from './home-routing.module';
import { HomepageComponent } from './homepage/homepage.component';
import { LoginComponent } from './login/login.component';
import { InviteFriendComponent } from '../invite-friend/invite-friend.component';
import { RegisterComponent } from './register/register.component';
import { PaymentComponent } from './payment/payment.component';
import { PaymentCardDetailComponent } from './payment-card-detail/payment-card-detail.component';
import { PaypalDetailsComponent } from './paypal-details/paypal-details.component';
import { SelectPlanComponent } from './select-plan/select-plan.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

@NgModule({
    declarations: [
        HomepageComponent,
        LoginComponent,
        InviteFriendComponent,
        RegisterComponent,
        PaymentComponent,
        PaymentCardDetailComponent,
        PaypalDetailsComponent,
        SelectPlanComponent,
        PaymentSuccessComponent,
        ForgotPasswordComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        HomeRoutingModule,
    ],
    exports: [
    ],
    providers: [
        UtilService,
        MakeHttpRequest,
        HandleRequestService,
        StudentAuthGuardService,
        StudentAuthInterceptor,
        StudentAuthService,
        PaymentPlansResolver
    ],
})
export class HomeModule {}