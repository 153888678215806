import { NgModule } from '@angular/core';
import { AvatarComponent } from './avatar.component';



@NgModule({
  declarations: [AvatarComponent],
  imports: [
  ],
  exports: [AvatarComponent]
})
export class AvatarModule { }
