import { Component, ElementRef, OnInit, Renderer, ViewEncapsulation ,ViewChildren, QueryList,} from '@angular/core';
import { UtilService } from 'src/app/shared/services/util.service';
import { HandleRequestService } from 'src/app/shared/services/handle-request.service';
import { APIResponse } from 'src/app/shared/models/api-response';
import { ActivatedRoute } from '@angular/router';
import { Student } from 'src/app/shared/models/student';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-toddler7',
  templateUrl: './toddler7.component.html',
  styleUrls: ['./toddler7.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class Toddler7Component implements OnInit {
  @ViewChildren('canvas') canvasElements: QueryList<ElementRef<HTMLCanvasElement>>;
  canvases = [
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
  ];

  resultData: any[] = [];
  student: Student;
  isIOS = false;


  defaultData = [
    ['36', '34', '12', '14', '51'],
    ['107', '842', '464', '260', '630'],
    ['37170', '36774', '24816', '16562', '55638'],
    ['326', '748', '462', '752', '1174'],
    [],
    ['25', '10', '31', '22', '28'],
    ['56', '0', '19', '43', '51'],
    ['144', '364', '252', '522', '96'],
    ['18', '47', '12', '8', '26'],
    [],
    ['72', '30', '49', '31', '53'],
    ['466', '483', '709', '941', '115'],
    ['43.4', '24.4', '11.1', '44.4', '11.1'],
    ['2121', '1010', '2412', '3442', '4423'],
    ['2993', '2378', '2795', '3276', '2052'],
    ['516', '744', '940', '731', '596'],
    [],
    ['25', '28', '26', '26', '24'],
    ['29', '75', '83', '60', '69'],
    ['125', '747', '469', '324', '195'],
    ['31', '12', '37', '16', '13'],
    [],
    [],
    ['33', '78', '71', '43', '67'],
    ['802', '657', '465', '627', '596'],
    ['12', '21.6', '78.9', '14.4', '82.6'],
    ['1111', '2411', '7031', '9499', '1355'],
    ['2352', '612', '3672', '1890', '3456'],
    ['438', '317', '631', '2637', '438'],
    [],
    ['28', '30', '29', '49', '43'],
    ['45', '34', '18', '50', '95'],
    ['978', '1432', '1479', '1810', '2286'],
    ['124', '136', '194', '294', '429'],
    [],
    [
      '35','17','8','10','7','31','16',
      '15','9','3','5','16','10','6',
      '8','4','48','30','18','19','7',
      '10','20','8','12','5','2','4',
      '41','18','12','3','4','26','13',
      '13','7','6','6','49','22','27',
      '11','16','7','45','21','24','12',
      '7','7'
    ],
    ['84', '78', '63', '70', '90'],    
    ['505', '698', '454', '673', '624'],
    ['35.5', '85.9', '97.8', '79.7', '69.9'],
    ['5457', '5989', '5658', '5485', '5995'],
    ['2233', '4095', '2170', '1564', '3024'],
    ['852', '321', '254', '574', '341'],
    [],
    ['30', '33', '35', '45', '30'],
    ['60', '49', '56', '44', '86'],
    ['234', '210', '332', '22', '424'],
    ['5526', '1548', '2380', '2416', '3255'],
    ['158', '188', '108', '191', '122'],
    [],
    ['22', '15', '22', '20', '12', '25', '25', '32', '19', '32'],
    ['59', '27', '81', '58', '62'],
    ['834', '844', '335', '703', '345'],
    ['40.2', '30.2', '42.1', '17.1', '47.1'],
    ['2402', '2121', '2410', '4514', '4314'],
    ['2584', '3995', '5440', '4958', '6460'],
    ['7', '8', '8', '7', '5'],
    [],
    ['54', '40', '49', '29', '23'],
    ['15', '33', '91', '89', '38'],
    ['165', '525', '210', '755', '675'],
    ['1264', '2874', '2475', '4790', '1116'],
    ['321', '236', '32', '177', '113'],
    [],
    [],
    ['28', '36', '88', '56', '65'],
    ['841', '771', '849', '723', '380'],
    ['79.6', '80.8', '76.8', '77.5', '82'],
    ['9415', '2760', '8588', '7776', '3038'],
    ['4158', '300', '2156', '6175', '1794'],
    ['9', '5', '3', '2', '3'],
    [],
    ['46', '55', '48', '35', '42'],
    ['65', '67', '78', '84', '29'],
    ['455', '656', '416', '968', '889'],
    [
      '0', '0', '0', '0', '0', '0', '0', '1', '0', '0', '0',
      '0', '1', '0', '0', '0', '0', '0', '0', '1', '0', '0',
      '1', '0', '1', '0', '0', '0', '0', '0', '1', '1', '0',
      '1', '1', '1', '1', '0', '0', '0', '0', '1', '0', '0',
      '1', '0', '0', '0', '0', '0', '0', '0', '1', '0', '0',
      '0', '0', '1', '1', '1', '1', '0', '0', '1', '0', '0',
      '0', '0', '0', '0', '0', '1', '1', '1', '1', '1', '0',
      '0', '0', '0', '0', '0', '1', '1', '0', '1', '0', '0',
      '0', '0', '0', '0', '0', '0', '0', '1', '1', '0', '0'
    ],
    ['55', '67', '22', '99', '72'],
    ['830', '611', '787', '337', '599'],
    ['16.7', '9.1', '29.8', '7.4', '21.9'],
    ['2061', '5609', '291', '809', '331'],
    ['4067', '5766', '300', '682', '1575'],
    ['12', '5', '6', '8', '4'],
    [],
    ['51', '34', '41', '38', '55'],
    ['45', '57', '47', '10', '54'],
    ['600', '811', '511', '817', '810'],
    ['8692', '6458', '29208', '32137', '8620'],
    ['557', '845', '851', '345', '741'],
    [],
    [
      '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0',
      '0', '0', '1', '1', '1', '0', '0', '0', '0', '0', '0', '1',
      '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '1',
      '0', '0', '0', '0', '1', '0', '0', '0', '0', '0', '0', '0',
      '0', '1', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0',
      '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0',
      '0', '0', '0', '1', '0', '0', '0', '0', '0', '0', '0', '0',
      '0', '0', '1', '1', '0', '0', '0', '0', '0', '0', '0', '0',
      '0', '0', '1', '1', '0', '1', '0', '0', '0', '0', '0', '0',
      '0', '0', '0', '0', '0', '0', '1', '0', '0', '0', '0', '0',
      '1', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0',
      '0', '0', '0', '1', '0', '1', '0', '0', '1', '0', '0', '0',
      '168', '20', '9', '18', '8', '2', '8', '20', '7', '50', '2', '5',
      '8', '32', '25', '3', '15', '9'
    ],
    ['63', '87', '55', '54', '49'],
    ['568', '527', '751', '638', '329'],
    ['94.4', '84.4', '61.3', '79.4', '44.9'],
    ['4435', '4083', '2393', '4544', '1970'],
    ['324', '3066', '7008', '2790', '2244'],
    ['8', '4', '9', '4', '7'],
    [],
    ['32', '45', '19', '45', '10'],
    ['76', '91', '87', '37', '53'],
    ['867', '928', '234', '212', '719'],
    ['27484', '45525', '24762', '15844', '8250'],
    ['609', '249', '681', '2532', '849'],
    [],
    [],
    ['60', '13', '42', '58', '66'],
    ['541', '339', '132', '511', '723'],
    ['55.6', '27.8', '20.5', '18.5', '17.5'],
    ['7978', '2791', '1095', '9115', '1593'],
    ['1925', '1058', '3312', '3652', '2450'],
    ['7', '8', '6', '8', '6'],
    [],
    ['64', '30', '44', '38', '50'],
    ['84', '42', '10', '99', '44'],
    ['943', '994', '834', '512', '899'],
    ['38600', '24822', '45472', '26996', '49329'],
    ['632', '2096', '798', '1363', '570'],
    [],
    [],
    ['47', '99', '37', '43', '51'],
    ['789', '393', '589', '674', '489'],
    ['58.6', '16.9', '75.8', '64.6', '45.4'],
    ['6998', '6912', '5503', '1908', '473'],
    ['7476', '5494', '3996', '714', '1620'],
    ['6', '6', '9', '7', '4'],
    [],
    ['33', '47', '66', '58', '38'],
    ['72', '80', '66', '54', '99'],
    ['798', '515', '469', '255', '556'],
    ['27260', '16362', '18666', '18196', '55764'],
    ['504', '382', '1453', '1097', '562'],
    [],
  ];
  type = 'toddler7';

  constructor(
    private utilService: UtilService,
    private httpService: HandleRequestService,
    private route: ActivatedRoute,
    private elRef: ElementRef,
    private toastr: ToastrService,
    private renderer: Renderer
  ) { }

  ngOnInit() {
    if (/iPad|iPhone|iPod/.test(window.navigator.userAgent)) {
      this.isIOS = true;
    }

    for (var i = 0; i < 130; i++) {
      this.resultData.push({
        values: [],
        times: '',
        count: 0,
        correctAnswers: 10,
        resultIsIncorrect: [false,false,false,false,false,false,false,false,false,false],
        finished: false
      })
    }
    this.route.data.subscribe(x => {
      if (x.hasOwnProperty('profile')) {
        const response = x.profile as APIResponse;
        this.student = response.data as Student;
      }

      this.httpService.post('/smartpdf/get', {
        type: this.type,
        user_id: this.student._id,
      }).then(
        (res: APIResponse) => {
          if (res.data.smartpdf) {
            this.resultData = res.data.smartpdf.data.result;
          }
          this.canvases.forEach((canvas, index) => {
            if (!canvas.context) {
              canvas.context = this.canvasElements.toArray()[index].nativeElement.getContext('2d');
            }
          });
          this.loadDrawing();
        }, () => {
         
        }
      );

      
    });


  }

  startDrawing(e: TouchEvent, index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = true;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const event = e.touches[0];
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.beginPath();
    canvas.context.moveTo(x, y);
  }

  draw(e: TouchEvent, index: number): void {
    const canvas = this.canvases[index];
    if (!canvas.drawing) return;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const event = e.touches[0];
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.lineTo(x, y);
    canvas.context.stroke();
  }

  stopDrawing(index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = false;
    canvas.context.closePath();
  }

  startDrawing1(event: MouseEvent, index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = true;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.beginPath();
    canvas.context.moveTo(x, y);
  }

  draw1(event: MouseEvent, index: number): void {
    const canvas = this.canvases[index];
    if (!canvas.drawing) return;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.lineTo(x, y);
    canvas.context.stroke();
  }

  stopDrawing1(index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = false;
    canvas.context.closePath();
  }

  saveDrawing(index: number): void {
    this.toastr.info('Saving...');
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const dataURL = canvasElement.toDataURL();
    this.httpService.post('/smartcanvas/update', {
      type: this.type,
      user_id: this.student._id,
      serial: index,
      data: dataURL
    }).then(
      (res: APIResponse) => {
        this.toastr.clear();
        this.toastr.success('Successfully Saved');
      }, () => {
      }
    );
  }

  async loadDrawing() {
    for (var index in this.canvases) {
      this.httpService.post('/smartcanvas/get', {
        type: this.type,
        user_id: this.student._id,
        serial: index
      }).then(
        (res: APIResponse) => {
          if (res.data.smartcanvas) {
            const img = new Image();
            img.onload = () => {
            const canvasElement = this.canvasElements.toArray()[index].nativeElement;
              const context = canvasElement.getContext('2d');
              context.clearRect(0, 0, canvasElement.width, canvasElement.height);
              context.drawImage(img, 0, 0);
            };
            img.src = res.data.smartcanvas.data;
          }
        }, () => {
         
        }
      );

      await this.delay(2000);
    }
    
  }

  backToSavedImage(index: number) {
    this.httpService.post('/smartcanvas/get', {
      type: this.type,
      user_id: this.student._id,
      serial: index
    }).then(
      (res: APIResponse) => {
        if (res.data.smartcanvas) {
          const img = new Image();
          img.onload = () => {
          const canvasElement = this.canvasElements.toArray()[index].nativeElement;
            const context = canvasElement.getContext('2d');
            context.clearRect(0, 0, canvasElement.width, canvasElement.height);
            context.drawImage(img, 0, 0);
          };
          img.src = res.data.smartcanvas.data;
        }
      }, () => {
       
      }
    );
  }

  clearCanvas(index: number): void {
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const context = canvasElement.getContext('2d');
    context.clearRect(0, 0, canvasElement.width, canvasElement.height);
  }

  canvasPropertiesChanges(): void {
    this.canvases.forEach(canvas => {
      if (!canvas.isEraser) {
        canvas.context.strokeStyle = canvas.brushColor;
      }
      canvas.context.lineWidth = canvas.brushSize;
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  quastionConfirm(index) {
    this.resultData[index].finished = true;
    if (this.resultData[index].count < 2) {
      this.resultData[index].count++;
      let allCorrect = true;
      for (let i in this.defaultData[index]) {
        if (this.defaultData[index][i] != this.resultData[index].values[i]) {
          allCorrect = false;
        }
      }
      if (!allCorrect && this.resultData[index].count == 1) {
        this.toastr.error('Corrige el resultado en rojo y vuelve a picar. Solo tienes una oportunidad de corregir.');
      }
    }
    this.saveData();
  }
  quastionChange(index) {
    this.resultData[index].finished = false;
  }

  quastionConfirm1(index) {
    this.resultData[index].finished = true;
    this.saveData();
  }

  clickedNumbers(i, j) {
    if (this.resultData[i].finished && this.resultData[i].count >= 2) {
      return;
    }
    this.resultData[i].finished = false;
    if (this.resultData[i].values[j] == '1') {
      this.resultData[i].values[j] = '0';
    } else {
      this.resultData[i].values[j] = '1';
    }
  }

  correctAnswerCheck(i, j) {
    if (!this.resultData[i].resultIsIncorrect[j] && this.resultData[i].finished){
      if (document.activeElement && document.activeElement !== document.body) {
        this.renderer.invokeElementMethod(
          this.elRef.nativeElement.ownerDocument.activeElement, 'blur');
      }
      this.resultData[i].resultIsIncorrect[j] = true;
      this.resultData[i].correctAnswers--;
      this.saveData();
    }
  }

  saveData() {
    this.httpService.post('/smartpdf/update', {
      type: this.type,
      user_id: this.student._id,
      data: {
        result: this.resultData
      }
    }).then(
      (res: APIResponse) => {
      }, () => {
      }
    );
  }
}
