import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HandleRequestService } from '../handle-request.service';
import { UtilService } from '../util.service';
import { STUDENT_AUTH } from '../app-config';
import { Student } from '../../models/student';

@Injectable()
export class StudentAuthInterceptor implements HttpInterceptor {
    copiedReq: HttpRequest<any>;

    constructor(private httpRequest: HandleRequestService,
                private utilService: UtilService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.utilService.retrieveLocalStorage(STUDENT_AUTH).pipe(
            mergeMap((student: Student) => {
                if (student) {
                    const copiedReq = req.clone({headers: req.headers.set('Authorization', 'Bearer ' + student.token)});
                    return next.handle(copiedReq);
                }

                return next.handle(req);
            })
        );
    }
}
