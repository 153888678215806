import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class MakeHttpRequest {
  private url: string;

  constructor(private http: HttpClient) {
    this.url = environment.domain + environment.apiURL;
  }

  requestFromServer(queryParam: string) {
    let headers: HttpHeaders;
    headers = new HttpHeaders().set('Accept', 'application/json');

    const req = new HttpRequest('GET', this.url + queryParam, {
        observe: 'json',
        headers
    });

    return this.http.request(req);
  }

  updateToServer(queryParam: string, postValue: object) {
    let headers: HttpHeaders;
    headers = new HttpHeaders().set('Accept', 'application/json');

    return this.http.put(this.url + queryParam, postValue, {
        responseType: 'json',
        headers
    });
  }

  postToServer(queryParam: string, postValue: object) {
    let headers: HttpHeaders;
    headers = new HttpHeaders().set('Accept', 'application/json');

    return this.http.post(this.url + queryParam, postValue, {
      responseType: 'json',
      headers
    });
  }

  updateRequest(queryParam: string, postValue: object) {
    let headers: HttpHeaders;
    headers = new HttpHeaders().set('Accept', 'application/json');

    return this.http.patch(this.url + queryParam, postValue, {
      responseType: 'json',
      headers
    });
  }

  deleteRequest(queryParam: string, postValue: object, userAuthorization?) {
    let headers: HttpHeaders;
    headers = new HttpHeaders().set('Accept', 'application/json');

    return this.http.delete(this.url + queryParam, {
      responseType: 'json',
      headers
    });
  }

  createFormData(object: any, form?: FormData, namespace?: string): FormData {
      const formData = form || new FormData();
      for (let property in object) {
          if (!object.hasOwnProperty(property) || !object[property]) {
              continue;
          }
          const formKey = namespace ? `${namespace}[${property}]` : property;
          if (object[property] instanceof Date) {
              formData.append(formKey, object[property].toISOString());
          } else if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
              this.createFormData(object[property], formData, formKey);
          } else {
              formData.append(formKey, object[property]);
          }
      }
      return formData;
  }

  fileUpload(parameter: string, fileItem: File[] | File, extraData: object): any {
      const apiCreateEndPoint = `${this.url}${parameter}`;
      let formData: FormData = new FormData();

      if (Array.isArray(fileItem)) {
        for (let i = 0; i < fileItem.length; i++) {
            if (fileItem[i]) {
                formData.append('file' + (i + 1), fileItem[i], fileItem[i].name);
            }
        }
      } else {
        formData.append('file', fileItem, fileItem.name);
      }

      // formData.append('fileItem', fileItem, fileItem.name);
      if (extraData) {
          formData = this.createFormData(extraData, formData);
      }

      const req = new HttpRequest('POST', apiCreateEndPoint, formData, {
          reportProgress: true,
          responseType: 'json'
      });

      return this.http.request(req);
  }

  list(): Observable<any>{
      const listEndpoint = `${this.url}files/`;
      return this.http.get(listEndpoint)
  }
}
