import { Injectable } from '@angular/core';
import { UtilService } from '../util.service';
import { STUDENT_AUTH } from '../app-config';
import { HandleRequestService } from '../handle-request.service';

@Injectable()
export class StudentAuthService {
    constructor(private httpCall: HandleRequestService,
                private utilService: UtilService) { }

    isAuthenticated() {
        const promise = new Promise(
            (resolve, reject) => {
                this.utilService.retrieveLocalStorage(STUDENT_AUTH).subscribe(
                    x => {
                        if (x) { resolve(true); } else { resolve(false); }
                    }
                );
            }
        );

        return promise;
    }
}
