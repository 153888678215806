import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HandleRequestService } from 'src/app/shared/services/handle-request.service';
import { APIResponse } from '../../shared/models/api-response';
import { UtilService } from 'src/app/shared/services/util.service';
import { HttpErrorResponse } from '@angular/common/http';
import { STUDENT_AUTH } from 'src/app/shared/services/app-config';
import { Router } from '@angular/router';
declare const grecaptcha: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @ViewChild('formRegister', { static: false }) formRegister: NgForm;
  errorMessage;
  processing = false;
  submitted = false;

  constructor(private httpService: HandleRequestService,
              private utilService: UtilService,
              private router: Router) { }

  ngOnInit() {
    
  }

  register() {

    if (this.formRegister.invalid) {
      this.utilService.showMessage('Kindly complete the form fields marked asterisks (*)');
    } else {

      grecaptcha.ready(() => {
        grecaptcha.execute('6Lc3t7kmAAAAAHubeP_-kLRz6ozTvf--E3ZcVOBl', {action: 'submit'}).then((token) => {
            // Add your logic to submit to your backend server here.
  
            if (token) {
              this.processing = true;
              this.httpService.post('/account/register', this.formRegister.value).then(
                (res: APIResponse) => {
                  this.processing = false;
                  if (!res.error) {
                    this.submitted = true;
                  } else {
                    this.utilService.showMessage(res.msg);
                  }
                }, (httpError: HttpErrorResponse) => {
                  this.processing = false;
                  this.utilService.showMessage('Request failed - ' + httpError.error.message);
                }
              );
            }
        });
      });
      
     
    }
  }

  reloadPage() {
    window.location.reload();
  }

}
