import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomepageComponent } from './homepage/homepage.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { PaymentComponent } from './payment/payment.component';
import { PaymentCardDetailComponent } from './payment-card-detail/payment-card-detail.component';
import { PaypalDetailsComponent } from './paypal-details/paypal-details.component';
import { SelectPlanComponent } from './select-plan/select-plan.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { StudentAuthGuardService } from '../shared/services/auth/student-auth-guard.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PaymentPlansResolver } from '../shared/resolvers/get-payment-plans-resolver.service';

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'password/:category', component: ForgotPasswordComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'payment', component: PaymentComponent, canActivate: [ StudentAuthGuardService ] },
    { path: 'payment/card', component: PaymentCardDetailComponent, canActivate: [ StudentAuthGuardService ] },
    { path: 'payment/paypal', component: PaypalDetailsComponent, canActivate: [ StudentAuthGuardService ] },
    { path: 'payment-plan', component: SelectPlanComponent, resolve: {
        response: PaymentPlansResolver
    }, canActivate: [ StudentAuthGuardService ] },
    { path: 'payment/success', component: PaymentSuccessComponent, canActivate: [ StudentAuthGuardService ] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HomeRoutingModule {}
