import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from '../shared/services/util.service';
import { STUDENT_AUTH } from '../shared/services/app-config';
import { HandleRequestService } from '../shared/services/handle-request.service';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router,
              private utilService: UtilService,
              private httpRequest: HandleRequestService) { }

  ngOnInit() {
    this.utilService.removeLocalStorageValue(STUDENT_AUTH).subscribe(
      xx => this.router.navigate(['/login'])
    );
  }

}
