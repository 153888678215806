import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-abacus',
  templateUrl: './abacus.component.html',
  styleUrls: ['./abacus.component.css']
})
export class AbacusComponent implements OnInit {
  a1: HTMLElement ;
  a2: HTMLElement ;
  a3: HTMLElement ;
  a4: HTMLElement ;
  a5: HTMLElement ;

  b1: HTMLElement ;
  b2: HTMLElement ;
  b3: HTMLElement ;
  b4: HTMLElement ;
  b5: HTMLElement ;

  c1: HTMLElement ;
  c2: HTMLElement ;
  c3: HTMLElement ;
  c4: HTMLElement ;
  c5: HTMLElement ;

  d1: HTMLElement ;
  d2: HTMLElement ;
  d3: HTMLElement ;
  d4: HTMLElement ;
  d5: HTMLElement ;

  e1: HTMLElement ;
  e2: HTMLElement ;
  e3: HTMLElement ;
  e4: HTMLElement ;
  e5: HTMLElement ;

  f1: HTMLElement ;
  f2: HTMLElement ;
  f3: HTMLElement ;
  f4: HTMLElement ;
  f5: HTMLElement ;

  g1: HTMLElement ;
  g2: HTMLElement ;
  g3: HTMLElement ;
  g4: HTMLElement ;
  g5: HTMLElement ;

  h1: HTMLElement ;
  h2: HTMLElement ;
  h3: HTMLElement ;
  h4: HTMLElement ;
  h5: HTMLElement ;

  i1: HTMLElement ;
  i2: HTMLElement ;
  i3: HTMLElement ;
  i4: HTMLElement ;
  i5: HTMLElement ;

  constructor() {}

  ngOnInit() {
    this.a1 = document.getElementById('a1');
    this.a2 = document.getElementById('a2');
    this.a3 = document.getElementById('a3');
    this.a4 = document.getElementById('a4');
    this.a5 = document.getElementById('a5');

    this.b1 = document.getElementById('b1');
    this.b2 = document.getElementById('b2');
    this.b3 = document.getElementById('b3');
    this.b4 = document.getElementById('b4');
    this.b5 = document.getElementById('b5');

    this.c1 = document.getElementById('c1');
    this.c2 = document.getElementById('c2');
    this.c3 = document.getElementById('c3');
    this.c4 = document.getElementById('c4');
    this.c5 = document.getElementById('c5');

    this.d1 = document.getElementById('d1');
    this.d2 = document.getElementById('d2');
    this.d3 = document.getElementById('d3');
    this.d4 = document.getElementById('d4');
    this.d5 = document.getElementById('d5');

    this.e1 = document.getElementById('e1');
    this.e2 = document.getElementById('e2');
    this.e3 = document.getElementById('e3');
    this.e4 = document.getElementById('e4');
    this.e5 = document.getElementById('e5');

    this.f1 = document.getElementById('f1');
    this.f2 = document.getElementById('f2');
    this.f3 = document.getElementById('f3');
    this.f4 = document.getElementById('f4');
    this.f5 = document.getElementById('f5');

    this.g1 = document.getElementById('g1');
    this.g2 = document.getElementById('g2');
    this.g3 = document.getElementById('g3');
    this.g4 = document.getElementById('g4');
    this.g5 = document.getElementById('g5');

    this.h1 = document.getElementById('h1');
    this.h2 = document.getElementById('h2');
    this.h3 = document.getElementById('h3');
    this.h4 = document.getElementById('h4');
    this.h5 = document.getElementById('h5');

    this.i1 = document.getElementById('i1');
    this.i2 = document.getElementById('i2');
    this.i3 = document.getElementById('i3');
    this.i4 = document.getElementById('i4');
    this.i5 = document.getElementById('i5');
  }


  oneToggle(bead2, bead3, bead4, bead5) {
    if (bead2.style.top === '105px' || bead2.style.top === '') {
      bead2.style.top = '80px';
    } else {
      bead2.style.top = '105px';
      bead3.style.top = '130px';
      bead4.style.top = '155px';
      bead5.style.top = '180px';
    }
  }

  twoToggle(bead2, bead3, bead4, bead5) {
    if (bead3.style.top === '130px' || bead3.style.top === '') {
      bead2.style.top = '80px';
      bead3.style.top = '105px';
    } else {
      bead3.style.top = '130px';
      bead4.style.top = '155px';
      bead5.style.top = '180px';
    }
  }

  threeToggle(bead2, bead3, bead4, bead5) {
    if (bead4.style.top === '155px' || bead4.style.top === '') {
      bead2.style.top = '80px';
      bead3.style.top = '105px';
      bead4.style.top = '130px';
    } else {
      bead4.style.top = '155px';
      bead5.style.top = '180px';
    }
  }

  fourToggle(bead2, bead3, bead4, bead5) {
    if (bead5.style.top === '180px' || bead5.style.top === '') {
      bead2.style.top = '80px';
      bead3.style.top = '105px';
      bead4.style.top = '130px';
      bead5.style.top = '155px';
    } else {
      bead5.style.top = '180px';
    }
  }

  fiveToggle(bead1) {
  console.log(bead1);
  if (bead1.style.top === '0px' || bead1.style.top === '') {
      bead1.style.top = '25px';
    } else {
      bead1.style.top = '0px';
    }
  }
}
