import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Student } from 'src/app/shared/models/student';
import { APIResponse } from 'src/app/shared/models/api-response';
import { lang } from 'src/lang/lang';
import { HandleRequestService } from 'src/app/shared/services/handle-request.service';
declare var $;

@Component({
  selector: 'app-hallofame',
  templateUrl: './hallofame.component.html',
  styleUrls: ['./hallofame.component.css']
})
export class HallofameComponent implements OnInit {
  student: Student;
  studentProgress: any;
  lang = lang;
  locale = 'es';
  students: Student[] = [];
  sortType: string = 'day';
  selectedStudent: Student;
  currentAvatar: any[] = [];
  avatars: any[];

  constructor(
    private httpService: HandleRequestService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    var ln = localStorage.getItem('locale');
    if(ln != null) {
      this.locale = ln;
    }

    

    this.route.data.subscribe(x => {
      if (x.hasOwnProperty('profile')) {
        const response = x.profile as APIResponse;
        this.student = response.data as Student;
        this.selectedStudent = this.student;
        this.currentAvatar = this.student.currentAvatar;
      }

      if (x.hasOwnProperty('progress')) {
        const response = x.progress as APIResponse;
        if (!response.error) {
          this.studentProgress = response.data;
        }
      }
      this.httpService.get('/account/scores').then(
        (res: APIResponse) => {
          this.students = res.data.students;
          this.students.sort((a, b) => (a._id == this.student._id? -1:b._id == this.student._id? 1:0));
  


          for (let item of this.students) {
            let now = new Date();
            let updatedDate = new Date(item.updated_date);
            if (now.getDay() > updatedDate.getDay()) {
              item.dailyScore = 0;
            }

            if (this.getWeek(now, 'ISO') > this.getWeek(updatedDate, 'ISO')) {
              item.weeklyScore = 0;
            }

            if (now.getMonth() > updatedDate.getMonth()) {
              item.monthlyScore = 0;
            } 

            if (now.getFullYear() > updatedDate.getFullYear()) {
              item.yearlyScore = 0;
            }
            
          }
  
          this.avatars = res.data.avatars;
          this.filterDay();
        }, () => {
        }
      );

    });

  }

  filterDay() {
    this.sortType = 'day';
    this.students.sort((a, b) => (a.dailyScore > b.dailyScore?-1:1));
  }
  filterWeek() {
    this.sortType = 'week';
    this.students.sort((a, b) => (a.weeklyScore > b.weeklyScore?-1:1));
  }
  filterMonth() {
    this.sortType = 'month';
    this.students.sort((a, b) => (a.monthlyScore > b.monthlyScore?-1:1));
  }
  filterYear() {
    this.sortType = 'year';
    this.students.sort((a, b) => (a.yearlyScore > b.yearlyScore?-1:1));
  }

  showProfile(student) { 

    if (student.avatarItems.length == 0) {
      let genderS = 'male';
      if (student.gender && student.gender.substring(0, 1).toLowerCase() == 'f') {
          genderS = 'female';
      }

      if (genderS == 'male') {
        student.avatarItems = [
            '63112ae07986c73656dcd6fc',
            '6311bc83b6d468458a89629a',
            '6311bf30b6d468458a89629b',
            '6311cbb5b6d468458a8962a1',
            '63122d7eb6d468458a8962a9',
            '6312307fb6d468458a8962ac',
            '631235e7b6d468458a8962b0',
            '6312380ab6d468458a8962b9',
            '63126b55b6d468458a8962c3',
            '63126beeb6d468458a8962c5',
            '63126da1b6d468458a8962c8',
            '63126e45b6d468458a8962c9',
            '63127607b6d468458a8962cb'
        ];
      } else {
        student.avatarItems = [
          '633c8241c8f06e25366805e5',
          '633c82d8c8f06e25366805e6',
          '633c83b9c8f06e25366805e7',
          '633c8421c8f06e25366805e8',
          '633c84d1c8f06e25366805e9',
          '633c851bc8f06e25366805ea',
          '633c854ac8f06e25366805eb',
          '633c85bac8f06e25366805ec',
          '633c87a8c8f06e25366805ee',
          '633c88d4c8f06e25366805ef',
          '633c8926c8f06e25366805f3',
          '633c8aadc8f06e25366805f4'
        ];
      }

    }

    this.currentAvatar = [];
    for(let item of this.avatars) {
      if(student.avatarItems.includes(item._id)) {
        this.currentAvatar.push(item);
      }
    }

    this.selectedStudent = student;

    $('#profile-modal').modal()
  }

  getWeek(date: Date, weekNumberingSystem: 'ISO' | 'US'): number {
    const dayOfWeek = date.getDay();
    let weekCode: number;
  
    if (weekNumberingSystem === 'ISO') {
      // ISO week numbering system starts on Monday and the first week of the year contains January 4th
      const jan4 = new Date(date.getFullYear(), 0, 4);
      const jan4DayOfWeek = jan4.getDay();
      const jan4WeekStart = new Date(jan4.getTime() - (jan4DayOfWeek - 1) * 24 * 60 * 60 * 1000);
      const diff = date.getTime() - jan4WeekStart.getTime();
      weekCode = Math.ceil(diff / (7 * 24 * 60 * 60 * 1000)) + 1;
    } else {
      // US week numbering system starts on Sunday and the first week of the year contains January 1st
      const jan1 = new Date(date.getFullYear(), 0, 1);
      const jan1DayOfWeek = jan1.getDay();
      const jan1WeekStart = new Date(jan1.getTime() - jan1DayOfWeek * 24 * 60 * 60 * 1000);
      const diff = date.getTime() - jan1WeekStart.getTime();
      weekCode = Math.floor(diff / (7 * 24 * 60 * 60 * 1000)) + 1;
      if (jan1DayOfWeek > 0) {
        weekCode += 1;
      }
    }
  
    return weekCode;
  }
}
