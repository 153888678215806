import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { APIResponse } from '../models/api-response';
import { Observable, from } from 'rxjs';
import { HandleRequestService } from '../services/handle-request.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GetHomeworkQuestionsResolver implements Resolve<APIResponse> {
    constructor(private makeRequest: HandleRequestService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): APIResponse | Observable<APIResponse> | Promise<APIResponse> {
        const category = route.params.category === 'classwork' ? 'cw' : 'hw';
        const day = route.queryParams.day;
        const type = route.queryParams.type;
        return from(this.makeRequest.get(`/levels/${route.params.level}/lessons/${route.params.lesson}/questions?category=${category}&day=${day}&type=${type}`))
                .pipe(
                    map((x: APIResponse) => x)
                );
    }
}
