import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HandleRequestService } from 'src/app/shared/services/handle-request.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { Router } from '@angular/router';
import { APIResponse } from 'src/app/shared/models/api-response';
import { STUDENT_AUTH } from 'src/app/shared/services/app-config';
import { HttpErrorResponse } from '@angular/common/http';
import { Student } from 'src/app/shared/models/student';
import { lang } from 'src/lang/lang';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('formLogin', { static: false }) formLogin: NgForm;
  errorMessage;
  processing = false;
  lang = lang;
  locale = 'es';

  constructor(private httpService: HandleRequestService,
              private utilService: UtilService,
              private router: Router) { }

  ngOnInit() {
    var ln = localStorage.getItem('locale');
    if(ln != null) {
      this.locale = ln;
    }

  }

  login() {
    if (this.formLogin.invalid) {
      this.utilService.showMessage('Kindly enter your username and password');
    } else {
      this.processing = true;
      this.httpService.post('/auth/login', this.formLogin.value).then(
        (res: APIResponse) => {
          this.processing = false;
          if (!res.error) {
            const student = res.data as Student;
            this.utilService.setValueToLocalStorage(STUDENT_AUTH, student, () => {
              if (!student.subscribed) {
                this.router.navigate(['/payment-plan']);
              } else {
                this.router.navigate(['/']);
              }
            });
          } else {
            this.utilService.showMessage(res.msg);
          }
        }, (httpError: HttpErrorResponse) => {
          this.processing = false;
          this.utilService.showMessage('Request failed - ' + httpError.error.message);
        }
      );
    }
  }

}
