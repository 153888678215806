import { Component, ElementRef, OnInit, Renderer, ViewEncapsulation, ViewChildren, QueryList} from '@angular/core';
import { UtilService } from 'src/app/shared/services/util.service';
import { HandleRequestService } from 'src/app/shared/services/handle-request.service';
import { APIResponse } from 'src/app/shared/models/api-response';
import { ActivatedRoute } from '@angular/router';
import { Student } from 'src/app/shared/models/student';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-toddler8',
  templateUrl: './toddler8.component.html',
  styleUrls: ['./toddler8.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class Toddler8Component implements OnInit {
  @ViewChildren('canvas') canvasElements: QueryList<ElementRef<HTMLCanvasElement>>;
  canvases = [
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
  ];

  resultData: any[] = [];
  student: Student;
  isIOS = false;


  defaultData = [
    [
      '0', '0', '0', '0', '0', '1', '1', '1', '1', '0', '0', '0', '0', '0', '0',
      '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '1', '1', '1', '1', '0',
      '0', '0', '0', '0', '0', '0', '1', '1', '1', '1', '0', '0', '0', '0', '0',
      '0', '0', '0', '0', '1', '1', '1', '1', '0', '0', '0', '0', '0', '0', '0',
      '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '1', '1', '1', '1',
      '1', '1', '1', '1', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0',
      '0', '0', '0', '0', '0', '0', '0', '0', '1', '1', '1', '1', '0', '0', '0',
    ], // 1
    ['118', '34', '122', '109', '75'], // 2
    ['426', '318', '616', '430', '554'], // 3
    ['72.9', '67.9', '73.3', '91.2', '91'], // 4
    ['9623', '5833', '4918', '3613', '7315'], // 5
    ['1404', '2320', '2592', '1152', '910'], // 6
    ['9', '6', '6', '7', '9'], // 7
    [], // 8
    ['28', '35', '32', '40', '28'], // 9
    [
      '1', '1', '3', '7', '3' ,'8', '5', '4', '5', '2',
      '1', '6', '1', '1', '7' ,'1', '8', '9', '4', '1',
      '9', '6', '5', '7', '2' ,'1', '2', '4', '1', '8',
    ], // 10
    ['788', '282', '236', '420', '703'], // 11
    ['15408', '29648', '33270', '29691', '11704'], // 12
    ['842', '750', '211', '642', '898'], // 13
    [], // 14
    [], // 15
    ['139', '180', '207', '137', '159'], // 16
    ['438', '346', '537', '815', '319'], // 17
    ['5649', '1581', '7406', '3694', '5505'], // 18
    ['7802', '2136', '4526', '3024', '3069'], // 19
    ['8', '9', '9', '9', '9'], // 20
    ['5.27', '2.37', '7.44', '4.26', '9.54'], // 21
    [], // 22
    ['3', '6', '9', '8', '1' ,'3', '4', '1', '3', '2',
      '1', '7', '9', '1', '3' ,'6', '2', '5', '1', '1',
      '8', '3', '4', '6', '3' ,'2', '9', '3', '2', '7'], // 23
    ['504', '399', '236', '501', '510'], // 24
    ['15168', '14652', '20408', '16935', '19304'], // 25
    ['573', '750', '536', '620', '574'], // 26
    [], // 27
    ['110', '44', '184', '173', '182'], // 28
    ['950', '1216', '288', '239', '357'], // 29
    ['3.08', '5.35', '4.79', '1.85', '5.16'], // 30
    ['21825', '69008', '26469', '34968', '20256'], // 31
    ['8', '16', '18', '12', '34'], // 32
    ['2071', '7963', '7649', '8848', '3687'], // 33
    [], // 34
    ['3', '1', '6', '5', '1' ,'3', '2', '1', '8', '7',
      '1', '2', '3', '2', '4' ,'3', '4', '9', '1', '3',
      '7', '3', null, null, '3' ,null, null, '8', null, null], // 35
    ['681', '652', '661', '584', '311'], // 36
    ['7.99', '5.1', '1.05', '7.94', '9.88'], // 37
    ['20288', '31815', '9687', '8144', '28455'], // 38
    ['753', '635', '2140', '1234', '932'], // 39
    [], // 40
    [], // 41
    ['263', '197', '221', '214', '177'], // 42
    ['577', '742', '298', '646', '748'], // 43
    ['7.2', '2.24', '4.31', '7.82', '7.23'], // 44
    ['10747', '4553', '52630', '19494', '7056'], // 45
    ['16', '43', '9', '13', '13'], // 46
    ['6313', '6216', '5374', '1711', '6433'], // 47
    [], // 48
    [null, '5', '6', null, '13' ,'24', null, '17', '21', null,
      '8', '9', '1', null, null ,'1', '1', '8', null, '15',
      '56', null, '7', '8', '1' ,null, null, '1', '1', '12'], // 49
    ['388', '549', '685', '424', '385'], // 50
    ['7.97', '3.88', '6.75', '2.98', '5.87'], // 51
    ['43212', '72072', '48024', '7828', '42259'], // 52
    ['748', '633', '2525', '369', '331'], // 53
    [], // 54
    [
      '15', '8', '12', '6', '11','7', '12', '8', '15', '9',
      '12', '6', '13', '9', '11','14', '9', '17', '8', '11',
      '9', '13', '7', '14', '9','11', '6', '15', '7', '11',
      '8', '17', '9', '12', '6','11', '8', '13', '7', '14',
      '5', '11', '4', '12', '6','15', '6', '12', '9', '15',
      '8', '15', '6', '13', '9','13', '6', '15', '7', '11',
      '9', '11', '8', '13', '7','12', '8', '15', '9', '18',
    ], // 55
    ['175', '144', '226', '238', '188'], // 56
    ['727', '719', '778', '524', '136'], // 57
    ['2.57', '3.79', '6.42', '5.88', '2.56'], // 58
    ['55020', '18018', '42596', '20787', '16074'], // 59
    ['6', '14', '33', '69', '17'], // 60
    ['3349', '1455', '6246', '3381', '6834'], // 61
    [], // 62
    [null, '6', '7', '1', '1' ,'8', '1', '4', '15', '1',
      '17', '28', '1', '3', '7' ,'1', '5', '9', '1', '11',
      '24', '1', '15', '24', '4' ,'1', '2', '1', '33', '56'], // 63
    ['488', '505', '507', '747', '403'], // 64
    ['3.14', '3.21', '2.14', '1.13', '3.79'], // 65
    ['23901', '32910', '45000', '19124', '35520'], // 66
    ['4119', '653', '1121', '363', '1009'], // 67
    [], // 68
    [
      '0', '0', '0', '0', '0', '0', '0', '0',
      '0', '0', '1', '0', '0', '0', '0', '0',
      '0', '0', '0', '0', '0', '0', '0', '0',
      '0', '0', '0', '0', '0', '0', '1', '0',
      '0', '0', '0', '0', '0', '0', '0', '0',
    ], // 69
    ['166', '123', '146', '142', '180'], // 70
    ['304', '358', '705', '695', '619'], // 71
    ['5.42', '6.46', '3.79', '8.15', '7.54'], // 72
    ['17546', '28440', '12204', '31176', '46540'], // 73
    ['34', '28', '37', '16', '16'], // 74
    ['6797', '5281', '3605', '4959', '7547'], // 75
    [], // 76
    ['1', '2', '42', null, '25' ,'28', '1', '1', '6', '1',
      '1', '40', '1', '7', '12' ,'2', '11', '12', '1', '15',
      '54', '3', '8', '21', '1' ,'13', '63', '4', '3', '4'], // 77
    ['306', '376', '870', '745', '202'], // 78
    ['9.22', '7.85', '4.18', '9.15', '6.9'], // 79
    ['22765', '14570', '11304', '47096', '41622'], // 80
    ['2123', '3267', '523', '1525', '545'], // 81
    [], // 82
    [
      '1', '1', '4', '0', '2','0', '2', '2', '1', '0',
      '2', '0', '3', '3', '2','1', '3', '0', '0', '1',
      '2', '1', '0', '1', '5','3', '2', '2', '2', '2',
      '2', '1', '2', '5', '1','3', '1', '2', '3', '3',
    ], // 83
    ['123', '161', '178', '162', '114'], // 84
    ['987', '743', '723', '781', '847'], // 85
    ['12.13', '13.59', '13.19', '10.15', '14.8'], // 86
    ['26572', '21150', '36038', '20643', '66640'], // 87
    ['29', '62', '36', '26', '37'], // 88
    ['6738', '2233', '8591', '3628', '1983'], // 89
    [], // 90
    [null, '17', '2', null, '21' ,'4', null, '19', '3', null,
      '43', '5', null, '15', '4' ,null, '39', '5', null, '23',
      '5', null, '29', '3', null ,'7', '2', null, '19', '2'], // 91
    ['321', '357', '673', '348', '507'], // 92
    ['3.38', '7.59', '5.9', '3.79', '0.95'], // 93
    ['28592', '83052', '13527', '39895', '27504'], // 94
    ['563', '423', '654', '823', '746'], // 95
    [], // 96
    [], // 97
    ['204', '203', '160', '199', '188'], // 98
    ['731', '544', '309', '574', '849'], // 99
    ['12.13', '13.59', '13.19', '10.15', '14.8'], // 100
    ['66906', '11256', '43945', '67800', '28161'], // 101
    ['9', '21', '27', '37', '29'], // 102
    ['4166', '4700', '9951', '6432', '5162'], // 103
    [], // 104
    [null, '36', '5', null, '10' ,'9', null, '22', '4', null,
      '61', '7', null, '68', '8' ,null, '23', '6', null, '45',
      '7', null, '15', '7', null ,'26', '6', null, '38', '6'], // 105
    ['212', '134', '917', '321', '764'], // 106
    ['9.42', '9.84', '0.95', '5.43', '0.84'], // 107
    ['42896', '32144', '41955', '46056', '20008'], // 108
    ['6', '19', '8', '5', '7'], // 109
    [], // 110
    ['2', '9', '8', '3'], // 111
    ['3', '11', '4', '5', '10', '5', '4', '5',], // 112
    ['177', '174', '3', '153', '188'], // 113
    ['851', '755', '624', '579', '458'], // 114
    ['17.67', '13.64', '11.75', '15.91', '11.12'], // 115
    ['11330', '19692', '4140', '5969', '31046'], // 116
    ['8', '9', '37', '26', '38'], // 117
    ['5132', '5237', '6821', '5113', '2897'], // 118
    [], // 119
    [null, '13', '8', '1', '4' ,'9', null, '49', '8', '4',
      null, null, null, '38', '9' ,'3', '62', '63', null, '88',
      '9', null, '5', '6', null ,'23', '4', '1', '5', '6'], // 120
    ['500', '425', '544', '546', '262'], // 121
    ['5.59', '1.16', '5.69', '3.53', '3.66'], // 122
    ['1560', '1925', '1131', '1904', '2881'], // 123
    ['9', '9', '7', '6', '4'], // 124
    [], // 125
    [], // 126
    ['128', '162', '144', '208', '211'], // 127
    ['561', '581', '448', '525', '366'], // 128
    ['11.42', '15.05', '16.21', '13.51', '10.47'], // 129
    ['9460', '7176', '26314', '31502', '10934'], // 130
    ['23', '16', '23', '16', '17'], // 131
    ['7464', '9590', '5777', '508', '2291'], // 132
    [], // 133
    ['17', '5', '7', '6', '4' ,'6', '11', '3', '9', '6',
      '3', '4', '10', null, null , '9', '1', '6', '11', '5',
      '9', '8', '2', '7', '6' ,'8', '9', '1', '31', '40'], // 134
    ['240', '458', '292', '600', '572'], // 135
    ['9.74', '4.36', '9.98', '9.84', '1.54'], // 136
    ['2418', '4940', '1998', '1505', '714'], // 137
    ['2', '6', '4', '5', '4'], // 138
    [], // 139
  ];
  type = 'toddler8';

  constructor(
    private utilService: UtilService,
    private httpService: HandleRequestService,
    private route: ActivatedRoute,
    private elRef: ElementRef,
    private toastr: ToastrService,
    private renderer: Renderer
  ) { }

  ngOnInit() {
    if (/iPad|iPhone|iPod/.test(window.navigator.userAgent)) {
      this.isIOS = true;
    }

    for (var i = 0; i < 139; i++) {
      this.resultData.push({
        values: [],
        times: null,
        count: 0,
        correctAnswers: 10,
        resultIsIncorrect: [false,false,false,false,false,false,false,false,false,false],
        finished: false
      })
    }
    this.route.data.subscribe(x => {
      if (x.hasOwnProperty('profile')) {
        const response = x.profile as APIResponse;
        this.student = response.data as Student;
      }

      this.httpService.post('/smartpdf/get', {
        type: this.type,
        user_id: this.student._id,
      }).then(
        (res: APIResponse) => {
          if (res.data.smartpdf) {
            this.resultData = res.data.smartpdf.data.result;
          }
          this.canvases.forEach((canvas, index) => {
            if (!canvas.context) {
              canvas.context = this.canvasElements.toArray()[index].nativeElement.getContext('2d');
            }
          });
          this.loadDrawing();
        }, () => {
         
        }
      );

      
    });


  }

  startDrawing(e: TouchEvent, index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = true;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const event = e.touches[0];
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.beginPath();
    canvas.context.moveTo(x, y);
  }

  draw(e: TouchEvent, index: number): void {
    const canvas = this.canvases[index];
    if (!canvas.drawing) return;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const event = e.touches[0];
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.lineTo(x, y);
    canvas.context.stroke();
  }

  stopDrawing(index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = false;
    canvas.context.closePath();
  }

  startDrawing1(event: MouseEvent, index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = true;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.beginPath();
    canvas.context.moveTo(x, y);
  }

  draw1(event: MouseEvent, index: number): void {
    const canvas = this.canvases[index];
    if (!canvas.drawing) return;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.lineTo(x, y);
    canvas.context.stroke();
  }

  stopDrawing1(index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = false;
    canvas.context.closePath();
  }

  saveDrawing(index: number): void {
    this.toastr.info('Saving...');
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const dataURL = canvasElement.toDataURL();
    this.httpService.post('/smartcanvas/update', {
      type: this.type,
      user_id: this.student._id,
      serial: index,
      data: dataURL
    }).then(
      (res: APIResponse) => {
        this.toastr.clear();
        this.toastr.success('Successfully Saved');
      }, () => {
      }
    );
  }

  async loadDrawing() {
    for (var index in this.canvases) {
      this.httpService.post('/smartcanvas/get', {
        type: this.type,
        user_id: this.student._id,
        serial: index
      }).then(
        (res: APIResponse) => {
          if (res.data.smartcanvas) {
            const img = new Image();
            img.onload = () => {
            const canvasElement = this.canvasElements.toArray()[index].nativeElement;
              const context = canvasElement.getContext('2d');
              context.clearRect(0, 0, canvasElement.width, canvasElement.height);
              context.drawImage(img, 0, 0);
            };
            img.src = res.data.smartcanvas.data;
          }
        }, () => {
         
        }
      );

      await this.delay(2000);
    }
    
  }

  backToSavedImage(index: number) {
    this.httpService.post('/smartcanvas/get', {
      type: this.type,
      user_id: this.student._id,
      serial: index
    }).then(
      (res: APIResponse) => {
        if (res.data.smartcanvas) {
          const img = new Image();
          img.onload = () => {
          const canvasElement = this.canvasElements.toArray()[index].nativeElement;
            const context = canvasElement.getContext('2d');
            context.clearRect(0, 0, canvasElement.width, canvasElement.height);
            context.drawImage(img, 0, 0);
          };
          img.src = res.data.smartcanvas.data;
        }
      }, () => {
       
      }
    );
  }

  clearCanvas(index: number): void {
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const context = canvasElement.getContext('2d');
    context.clearRect(0, 0, canvasElement.width, canvasElement.height);
  }

  canvasPropertiesChanges(): void {
    this.canvases.forEach(canvas => {
      if (!canvas.isEraser) {
        canvas.context.strokeStyle = canvas.brushColor;
      }
      canvas.context.lineWidth = canvas.brushSize;
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  quastionConfirm(index) {
    this.resultData[index].finished = true;
    if (this.resultData[index].count < 2) {
      this.resultData[index].count++;
      let allCorrect = true;
      for (let i in this.defaultData[index]) {
        if (this.defaultData[index][i] != this.resultData[index].values[i]) {
          allCorrect = false;
        }
      }
      if (!allCorrect && this.resultData[index].count == 1) {
        this.toastr.error('Corrige el resultado en rojo y vuelve a picar. Solo tienes una oportunidad de corregir.');
      }
    }
    this.saveData();
  }
  quastionChange(index) {
    this.resultData[index].finished = false;
    for (let i in this.resultData[index].values) {
      if (this.resultData[index].values[i] == '') {
        this.resultData[index].values[i] = null;
      }
    }
  }

  quastionConfirm1(index) {
    this.resultData[index].finished = true;
    this.saveData();
  }

  clickedNumbers(i, j) {
    if (this.resultData[i].finished && this.resultData[i].count >= 2) {
      return;
    }
    this.resultData[i].finished = false;
    if (this.resultData[i].values[j] == '1') {
      this.resultData[i].values[j] = '0';
    } else {
      this.resultData[i].values[j] = '1';
    }
  }

  correctAnswerCheck(i, j) {
    if (!this.resultData[i].resultIsIncorrect[j] && this.resultData[i].finished){
      if (document.activeElement && document.activeElement !== document.body) {
        this.renderer.invokeElementMethod(
          this.elRef.nativeElement.ownerDocument.activeElement, 'blur');
      }
      this.resultData[i].resultIsIncorrect[j] = true;
      this.resultData[i].correctAnswers--;
      this.saveData();
    }
  }

  saveData() {
    this.httpService.post('/smartpdf/update', {
      type: this.type,
      user_id: this.student._id,
      data: {
        result: this.resultData
      }
    }).then(
      (res: APIResponse) => {
      }, () => {
      }
    );
  }
}
