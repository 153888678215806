import { Injectable } from '@angular/core';
import { MakeHttpRequest } from 'src/app/shared/services/http.service';
import { from, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';

declare let window: any;

@Injectable({ providedIn: 'root' })
export class UtilService {
    public readNotification = new Subject<boolean>();
    public readSafePayTransactions = new Subject<boolean>();
    public errorMessage = new Subject<string>();
    public successMessage = new Subject<string>();
    public appIsLoading = new Subject<boolean>();
    storage = window.localStorage;

    constructor(private httpService: MakeHttpRequest,
                protected storageMap: StorageMap,
                private router: Router) { }

    public setValueToLocalStorage(key, value, callback) {
        this.storageMap.set(key, value).subscribe(
            (resp) => callback(resp),
            (error) => callback(error)
        );
    }

    public retrieveLocalStorage(key) {
        const promise = new Promise((resolve, reject) => {
            this.storageMap.get(key).subscribe(
                (resp) => resolve(resp),
                (error) => reject(error)
            );
        });

        return from(promise);
    }

    public removeLocalStorageValue(key) {
        const promise = new Promise((resolve, reject) => {
            this.storageMap.delete(key).subscribe(
                (resp) => resolve(true),
                (error) => reject(false)
            );
        });

        return from(promise);
    }

    truncateString(str, num = 50) {
      if (str.length <= num) {
        return str;
      }
      return str.slice(0, num) + '...';
    }

    shuffle(array) {
      return array.sort(() => Math.random() - 0.5);
    }

    checkValidURL(url, a = false) {
        if (!a) {
            if (url.slice(0, 4) === 'http' || url.slice(0, 5) === 'https') {
                return false;
            }
        }

        const reg = new RegExp(
            /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
        );

        return reg.test(url);
    }

    checkValidUsername(username) {
        // Regex for Valid Characters i.e. Alphabets, Numbers, _, @, . and -.
        const regex = /^[A-Za-z0-9_@\-\.]+$/;

        // Validate TextBox value against the Regex.
        return regex.test(username);
    }

    isAuthenticated(category) {
        const promise = new Promise(
            (resolve, reject) => {
                this.retrieveLocalStorage(category).subscribe(loggedInData => {
                    if (loggedInData) {
                        resolve(true);
                    } else { reject(false); }
                });
            }
        );

        return promise;
    }

    showMessage(message) {
        if (window.hasOwnProperty('cordova')) {
            window.plugins.toast.showWithOptions({
              message,
              duration: 'long', // 2000 ms
              position: 'bottom'
            });
        } else {
            this.errorMessage.next(message);
        }
    }

    showMessageSmartPDF(message) {
        if (window.hasOwnProperty('cordova')) {
            window.plugins.toast.showWithOptions({
              message,
              duration: 'long', // 2000 ms
              position: 'bottom'
            });
        } else {
            this.errorMessage.next(message);
        }
    }
}
