import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { APIResponse } from 'src/app/shared/models/api-response';
import { Student } from 'src/app/shared/models/student';
import { Level } from 'src/app/shared/models/student_level';
import { Question, QuestionResponse } from 'src/app/shared/models/question';
import { HandleRequestService } from 'src/app/shared/services/handle-request.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { from } from 'rxjs';
import { bufferCount } from 'rxjs/operators';
// import { Abacus } from './abacus';
import io from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { STUDENT_AUTH } from 'src/app/shared/services/app-config';
import { ThrowStmt } from '@angular/compiler';
import { lang } from 'src/lang/lang';
declare var Swiper;
declare var $;

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit, AfterViewInit, OnDestroy {
  levelInfo: Level;
  student: Student;
  studentProgress: any;
  nextLesson: any;
  lessonNumber;
  questionResponse: QuestionResponse[] = [];
  questionMeta: any;
  staticQuestions: Question[] = [];
  currentStaticNumbers = [];
  countPassed = 0;
  isCheckClicked = false;
  isReal = 0;
  points = 0;
  audibleQuestions: Question[] = [];
  flashQuestions: Question[] = [];
  activeTab;
  voices = [];
  synth;
  speakingRate = 1;
  flashRate = 1000;
  currentPosition = 0;
  isWrong = true;

  mathematicalSigns = ['*', '-', '+', '/'];
  mathematicalWords = ['times', 'minus', 'plus', 'divide by'];
  flashCurrentCharacter;
  flashIsStart = false;
  displayInterval;
  noProgress = false;
  // showAbacus = false;
  category;
  day;
  type;
  levelStep = 0;
  socket: any;
  isClassActive;
  classEnded;
  message;
  isDecimal = false;
  allPassed = false;
  operation = "+";
  lang = lang;
  locale = 'es';
  constructor(private route: ActivatedRoute,
              private handleRequest: HandleRequestService,
              private utilService: UtilService,
              private router: Router,
              private ngZone: NgZone) { }

  ngOnInit() {
    var ln = localStorage.getItem('locale');
    if(ln != null) {
      this.locale = ln;
    }



    this.initLoadData();
  }

  ngAfterViewInit() {
    // tslint:disable-next-line: no-unused-expression
    new Swiper('.swiper-container', {
      direction: 'horizontal',
      loop: false,
      pagination: {
          el: '.swiper-pagination',
      },
      navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
      },
      scrollbar: {
          el: '.swiper-scrollbar',
      },
    });
  }

  ngOnDestroy(): void {
    if (this.category === 'classroom') {
      this.socket.close();
    }
  }

  initLoadData() {

    this.category = this.route.snapshot.params.category;
    if(this.route.snapshot.queryParams.isReal) {
      this.isReal = this.route.snapshot.queryParams.isReal;
    }

    if (this.category === 'classwork') {
      this.utilService.retrieveLocalStorage(STUDENT_AUTH).subscribe(
        (stud: Student) => {
          this.socket = io(`${environment.domain}`, {
            query: {
              token: stud.token,
            },
          });
          this.socketEvents();
        }
      );
    }

    if ('speechSynthesis' in window) {
      this.synth = window.speechSynthesis;
      this.populateVoiceList();
    }

    // this.ngZone.runOutsideAngular(() => {
    //   const abacusStatus = new Abacus('abacusStatic', 0);
    //   abacusStatus.init();
    // });

    this.route.data.subscribe((data: Data) => {

      if (data.hasOwnProperty('profile')) {
        const response = data.profile as APIResponse;
        if (!response.error) {
          this.student = response.data;
        }
      }

      if (data.hasOwnProperty('levels')) {
        const response = data.levels as APIResponse;
        if (!response.error) {
          this.levelInfo = response.data;
        }
      }

      if (data.hasOwnProperty('progress')) {
        const response = data.progress as APIResponse;
        if (!response.error) {
          this.studentProgress = response.data;
          this.noProgress = Object.keys(this.studentProgress).length === 0 && this.studentProgress.constructor === Object;
          this.checkCurrentLevel();
        }
      }

      if (data.hasOwnProperty('next')) {
        const response = data.next as APIResponse;
        if (!response.error) {
          this.nextLesson = response.data;
        }
      }

      if (data.hasOwnProperty('questions')) {
        const response = data.questions as APIResponse;
        if (!response.error) {
          this.questionResponse = response.data[this.category];
          this.questionMeta = response.data.meta;
          this.isDecimal = this.questionMeta.isDecimal;
          for (const level of this.levelInfo.levels) {
            if (level._id == this.questionMeta.level) {
              this.levelStep = level.step;
            }
          } 
          for(var item of this.questionMeta.operations) {
            if(item == "*") {
              this.operation = "×";
            }
            if(item == "/") {
              this.operation = "÷";
            }
          }

          let staticQuestions = [];
          let audibleQuestions = [];
          let flashQuestions = [];
          this.questionResponse.forEach(resp => {
            if (resp.type === 's') {
              staticQuestions = resp.questions;
              this.staticQuestions = [];
              let sno = 1;
              for (const quest of staticQuestions) {
                let answer = eval(quest);
                if(this.isDecimal) {
                  answer = answer.toFixed(2);
                }
                const record: Question = {
                  sno,
                  question: quest,
                  answer: answer,
                  inputedAnswer: '',
                  isCorrect: undefined,
                  isAnswered: undefined,
                };
                this.staticQuestions.push(record);
                sno++;
              }
            }
            if (resp.type === 'a') {
              audibleQuestions = resp.questions;
              let sno = 1;
              for (const quest of audibleQuestions) {

                let answer = eval(quest);
                if(this.isDecimal) {
                  answer = answer.toFixed(2);
                }

                const record: Question = {
                  sno,
                  question: quest,
                  answer: answer,
                  inputedAnswer: '',
                  isCorrect: undefined,
                  isAnswered: undefined,
                };
                this.audibleQuestions.push(record);
                sno++;
              }
            }
            if (resp.type === 'f') {
              flashQuestions = resp.questions;
              let sno = 1;
              for (const quest of flashQuestions) {

                let answer = eval(quest);
                if(this.isDecimal) {
                  answer = answer.toFixed(2);
                }

                const record: Question = {
                  sno,
                  question: quest,
                  answer: answer,
                  inputedAnswer: '',
                  isCorrect: undefined,
                  isAnswered: undefined,
                };
                this.flashQuestions.push(record);
                sno++;
              }
            }
          });
          if (this.staticQuestions.length > 0) {
            this.activeTab = 'static';
            this.getCurrentStaticNumbers();
          } else if (this.audibleQuestions.length > 0) {
            this.activeTab = 'audible';
            $('#audio-speed').modal();
          } else if (this.flashQuestions.length > 0) {
            this.activeTab = 'flash';
            $('#flash-speed').modal();
          }

          if (this.isReal == 0) {
            $('#select-option').modal();
          }
        }
      }

      this.lessonNumber = this.route.snapshot.queryParams.lesson;
      this.day = this.route.snapshot.queryParams.day;
      this.type = this.route.snapshot.queryParams.type;

    });

    this.populateVoiceList();
  }

  socketEvents() {
    this.socket.on('connect', () => {
      this.isClassActive = true;
    });

    this.socket.on('disconnect', () => {
      this.message = 'The classroom was disconnected for some reason';
      this.isClassActive = false;
      $('#classEndedModal').modal('show');
    });

    this.socket.on('class_dismissed', () => {
      this.classEnded = true;
      this.message = 'Your class has ended';
      $('#classEndedModal').modal('show');
    });

    this.socket.on('class_started', () => {
      this.isClassActive = true;
      $('#classEndedModal').modal('hide');
    });

    this.socket.on('null_class', () => {
      this.message = 'You don\'t have any class assigned yet';
      $('#classEndedModal').modal('show');
    });

    this.socket.on('message', (message) => {
      // console.log('the message - ' + message);
    });

    this.socket.on('f_error', (error) => {
      // console.log('the f_error - ' + error);
      this.message = 'Your teacher have not started a class yet';
      this.isClassActive = false;
      $('#classEndedModal').modal('show');
    });

    this.socket.on('error', (error) => {
      // console.error('the error - ' + error);
    });
  }

  getAvatar() {
    if (typeof  this.student.avatar != "object") {
      return this.student.avatar;
    } else {
      return 'assets/images/avatar.png';
    }
  }

  onChangeStatus(value) {
    var url = window.location.href;
    url = url.replace('&isReal=1', '');
    url = url.replace('&isReal=0', '');
    url+= `&isReal=${value}`;
    window.location.href = url;
    window.location.reload();
  }

  checkCurrentLevel() {
    const levels = this.levelInfo.levels;
    let currentIndex;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < levels.length; i++) {
      if (!this.noProgress && levels[i]._id === this.studentProgress.level)  {
        currentIndex = i;
      }
      if (currentIndex) {
        if (currentIndex > i) {
          levels[i].status = 'done';
        } else if (currentIndex < i) {
          levels[i].status = 'pending';
        } else  {
          levels[i].status = 'current';
        }
      } else {
        levels[i].status = 'done';
      }
    }
  }

  populateVoiceList() {
    const voices = this.synth.getVoices();

    for (const voice of voices) {
      const option = {
        name: voice.name,
        lang: voice.lang,
        default: false
      };

      if (voice.default) {
        option.default = true;
      }
      this.voices.push(option);
    }
  }

  onChangeText() {

    const value = $("#input-value").val();

    if(value == "") {
      this.isWrong = true;
    } else {
      this.isWrong = false;
    }
  }

  checkValue() {
    const answer = $("#input-value").val();

    if(answer == '') return;

    $("#input-value").val('');
    this.isWrong = true;

    if(this.activeTab == 'static') {
      this.nextStatic(answer);
    }

    if(this.activeTab == 'audible') {
      this.nextAudible(answer);
    }

    if(this.activeTab == 'flash') {
      this.nextFlash(answer);

    }
    
  }

  nextStatic(answer) {

    if (this.currentPosition >= this.staticQuestions.length) {
      // this.progress('static');
    } else {
      // tslint:disable-next-line: triple-equals
      if(this.staticQuestions[this.currentPosition].answer == answer) {
        this.staticQuestions[this.currentPosition].isCorrect = true;
      } else {
        this.staticQuestions[this.currentPosition].isCorrect = false;
      }

      this.currentPosition++;

      if(this.currentPosition == this.staticQuestions.length) {

        var correctCount = 0;
        this.allPassed = true;

        for (const audible of this.staticQuestions) {
          if(audible.isCorrect) {
            correctCount++;
          }
        }

        this.points = Math.round(correctCount / this.staticQuestions.length * 100);

      } else {
        this.getCurrentStaticNumbers();
      }

    }
  }

  getCurrentStaticNumbers() {
    const question = this.staticQuestions[this.currentPosition].question;
    this.currentStaticNumbers = question.replace(/\+/g, '').replace(/\*/g, ' ').split(' ');
    console.log(this.currentStaticNumbers);
  }

  startAudible() {
    
    let question = this.audibleQuestions[this.currentPosition].question;

    question = question.replace(/\./g, 'punto').replace(/\+/g, ' mas ').replace(/-/g, ' menos ').replace(/\*/g, ' por ').replace(/\//g, ' entre ');
    const utterThis = new SpeechSynthesisUtterance(question);
    utterThis.lang = 'es-ES';
    utterThis.rate = +this.speakingRate;
    this.synth.cancel();
    this.synth.speak(utterThis);
  }

  startFlash() {
    const question = this.flashQuestions[this.currentPosition].question;
    let flashCurrentQuestion = question.replace(/\+/g, '').replace(/\*/g, ' ').replace(/\//g, ' ').split(' ');
    if(question.includes('(')) {
      flashCurrentQuestion = [question];
    }
    
    let startIndex = 0;
    this.flashIsStart = true;
    this.flashCurrentCharacter = this.lang[this.locale]['beReady'];
    if(this.displayInterval != null) {
      clearInterval(this.displayInterval);
    }

    setTimeout(() => {
      this.flashCurrentCharacter = "";
    }, this.flashRate-100);
    this.displayInterval = setInterval(() => {
      this.flashCurrentCharacter = flashCurrentQuestion[startIndex];

      if (startIndex === flashCurrentQuestion.length) {
        this.flashIsStart = false;
        clearInterval(this.displayInterval);

        this.flashCurrentCharacter = this.lang[this.locale]['writeDownYourAnswer'];

      } else {
        setTimeout(() => {
          this.flashCurrentCharacter = "";
        }, this.flashRate-100);
      }

      startIndex++;

    }, this.flashRate);
  }

  nextAudible(answer) {
    this.isWrong = false;

    if (this.currentPosition === this.audibleQuestions.length) {
      // this.progress();
    } else {
      if(this.audibleQuestions[this.currentPosition].answer == answer) {
        this.audibleQuestions[this.currentPosition].isCorrect = true;
      } else {
        this.audibleQuestions[this.currentPosition].isCorrect = false;
      }

      this.currentPosition++;

      if(this.currentPosition == this.audibleQuestions.length) {

        var correctCount = 0;

        this.allPassed = true;

        for (const audible of this.audibleQuestions) {
          if(audible.isCorrect) {
            correctCount++;
          }
        }

        this.points = Math.round(correctCount / this.audibleQuestions.length * 100);

        // $('#audibleResult').modal('show');
      } else {
        this.startAudible();
      }
    }
  }

  nextFlash(answer) {

    if (this.currentPosition === this.flashQuestions.length) {

    } else {
      if (this.flashQuestions[this.currentPosition].answer == answer) {
        this.flashQuestions[this.currentPosition].isCorrect = true;
      } else {
        this.flashQuestions[this.currentPosition].isCorrect = false;
      }

      this.currentPosition++;


      if(this.currentPosition == this.flashQuestions.length) {

        var correctCount = 0;
        this.allPassed = true;

        for (const flash of this.flashQuestions) {
          if(flash.isCorrect) {
            correctCount++;
          }
        }

        this.points = Math.round(correctCount / this.flashQuestions.length * 100);

      } else {
        this.startFlash();
      }
    }

  }

  progress() {
    let isAllPassed = true;
    let questions;
    if (this.activeTab === 'static') {
      questions = this.staticQuestions;
    } else if (this.activeTab === 'audible') {
      questions = this.audibleQuestions;
    } else if (this.activeTab === 'flash') {
      questions = this.flashQuestions;
    }

    if (isAllPassed) {
      this.currentPosition = 0;
      if (this.activeTab === 'static') {
        $('#staticResult').modal('hide');
        if (this.audibleQuestions.length > 0) {
          this.activeTab = 'audible';
        } else if (this.flashQuestions.length > 0) {
          this.activeTab = 'flash';
        } else {
          this.handleProgress();
        }
      } else if (this.activeTab === 'audible') {
        $('#audibleResult').modal('hide');
        if (this.flashQuestions.length > 0) {
          this.activeTab = 'flash';
        } else {
          this.handleProgress();
        }
      } else if (this.activeTab === 'flash') {
        $('#flashResult').modal('hide');
        this.handleProgress();
      }
    }
  }

  handleProgress() {

    if(!this.isReal) {
      window.location.reload();
      return;
    }

    if(this.points < 60) {
      window.location.reload();
      return;
    }


    this.resetAllVariables();

    if (Object.keys(this.studentProgress).length === 0 && this.studentProgress.constructor === Object) {
      
      this.graduate();
    } else {
      if (this.studentProgress.lesson == this.questionMeta._id 
        && this.studentProgress.day == this.day
        && this.studentProgress.type == this.type) {
          this.graduate();
      } else {
        this.router.navigate(['/dashboard/homework']);
      }
    }
  }

  graduate() {
    this.utilService.appIsLoading.next(true);
    this.handleRequest.post(`/progress/advance`, {'points': this.points}).then(
      (res: APIResponse) => {
        this.utilService.appIsLoading.next(false);
        if (!res.error) {
          const data = res.data;
          this.redirectTo(`/dashboard/${this.category}/${data.level}/${data.lesson}`, { queryParams: {lesson: data.group, day: data.day, type: data.type }})
        } else {
          this.router.navigate(['/dashboard/homework']);
        }
      }, error => {
        this.utilService.appIsLoading.next(false);
        this.router.navigate(['/dashboard/homework']);
      }
    );
  }

  close(category) {
    $('#' + category + 'Result').modal('hide');
    let questions;
    if (category === 'static') {
      if (this.countPassed == this.staticQuestions.length) {
        this.progress();
      }
    } else {
      if (category === 'audible') {
        questions = this.audibleQuestions;
      } else if (category === 'flash') {
        questions = this.flashQuestions;
      }
      if (this.currentPosition === questions.length) {
        this.progress();
      }
    }
  }

  closeClassEnded() {
    $('#classEndedModal').modal('hide');
    this.router.navigate(['/']);
  }

  resetAllVariables() {
    this.questionResponse = [];
    this.flashQuestions = [];
    this.staticQuestions = [];
    this.audibleQuestions = [];
    this.currentPosition = 0;
    this.countPassed = 0;
    this.isCheckClicked = false;
  }

  // toggleAbacus(status) {
  //   this.showAbacus = status;
  // }

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
    charactersLength));
   }
   return result;
  }

  redirectTo(uri:string, params){
    this.router.navigateByUrl('/dashboard/homework', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri], params));
 }

 showSpeedModal() {
   if(this.activeTab == 'audible') {
     $('#audio-speed').modal();
   }

   if(this.activeTab == 'flash') {
    $('#flash-speed').modal();
  }
 }

}
