import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { APIResponse } from 'src/app/shared/models/api-response';
import { Student } from 'src/app/shared/models/student';

@Component({
  selector: 'app-smartpdf',
  templateUrl: './smartpdf.component.html',
  styleUrls: ['./smartpdf.component.css']
})
export class SmartpdfComponent implements OnInit {
  locale = 'es';
  student: Student;
  smartPdfs = [];

  constructor(
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    var ln = localStorage.getItem('locale');
    if(ln != null) {
      this.locale = ln;
    }

    this.route.data.subscribe((data: Data) => {
      if (data.hasOwnProperty('profile')) {
        const response = data.profile as APIResponse;
        this.student = response.data as Student;
        if (this.student['age_bracket'] == 'RUNNER') {
          for (let i = 0; i < 8; i++) {
            this.smartPdfs.push({
              'route': `/dashboard/smartpdf/runner${i+1}`,
              'locked': i >= this.student.level,
              'image': `assets/images/smartbooks/runner${i+1}.png`
            });
          }
        } else {
          for (let i = 0; i < 10; i++) {
            this.smartPdfs.push({
              'route': `/dashboard/smartpdf/toddler${i+1}`,
              'locked': i >= this.student.level,
              'image': `assets/images/smartbooks/toddler${i+1}.png`
            });
          }
        }
      }
    });
  }
}
