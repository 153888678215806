import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HandleRequestService } from 'src/app/shared/services/handle-request.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { Router, ActivatedRoute } from '@angular/router';
import { APIResponse } from 'src/app/shared/models/api-response';
import { Student } from 'src/app/shared/models/student';
import { STUDENT_AUTH } from 'src/app/shared/services/app-config';
import { HttpErrorResponse } from '@angular/common/http';
import { lang } from 'src/lang/lang';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('formForgotLogin', { static: false }) formForgotLogin: NgForm;
  @ViewChild('formResetLogin', { static: false }) formResetLogin: NgForm;
  errorMessage;
  processing = false;
  reset_token;
  category;
  lang = lang;
  locale = 'es';
  constructor(private httpService: HandleRequestService,
              private utilService: UtilService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {

    var ln = localStorage.getItem('locale');
    if(ln != null) {
      this.locale = ln;
    }
    this.route.params.subscribe(x => {
      if (x.hasOwnProperty('category')) {
        this.category = x.category;

        if (this.category === 'reset' && !this.route.snapshot.queryParams.reset_token) {
          this.router.navigate(['/login']);
        } else {
          this.reset_token = this.route.snapshot.queryParams.reset_token;
        }
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  retrieve() {
    if (this.formForgotLogin.invalid) {
      this.utilService.showMessage('Kindly enter your username');
    } else {
      this.processing = true;
      this.httpService.post('/auth/password/reset/request', this.formForgotLogin.value).then(
        (res: APIResponse) => {
          this.processing = false;
          if (!res.error) {
            this.utilService.successMessage.next(res.msg);
          } else {
            this.utilService.showMessage(res.msg);
          }
        }, (httpError: HttpErrorResponse) => {
          this.processing = false;
          this.utilService.showMessage('Request failed - ' + httpError.error.message);
        }
      );
    }
  }

  reset() {
    if (this.formResetLogin.invalid) {
      this.utilService.showMessage('Kindly enter your username');
    } else {
      if (this.formResetLogin.value.password !== this.formResetLogin.value.cpassword) {
        this.utilService.showMessage('Passwords are mismatching, kindly confirm');
      } else {
        this.processing = true;
        this.httpService.post('/auth/password/reset', {
          ...this.formResetLogin.value,
          reset_token: this.reset_token
        }).then(
          (res: APIResponse) => {
            this.processing = false;
            if (!res.error) {
              this.utilService.successMessage.next(res.msg);
              setInterval(() => this.router.navigate(['/login']), 5000);
            } else {
              this.utilService.showMessage(res.msg);
            }
          }, (httpError: HttpErrorResponse) => {
            this.processing = false;
            this.utilService.showMessage('Request failed - ' + httpError.error.message);
          }
        );
      }
    }
  }

}
