import { COMPILER_OPTIONS, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import { APIResponse } from 'src/app/shared/models/api-response';
import { Student } from 'src/app/shared/models/student';
import { Level } from 'src/app/shared/models/student_level';
import { HttpErrorResponse } from '@angular/common/http';
import { HandleRequestService } from 'src/app/shared/services/handle-request.service';
import { lang } from 'src/lang/lang';
import {
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexChart,
  ApexFill,
  ChartComponent,
  ApexStroke
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  stroke: ApexStroke;
};
@Component({
  selector: 'app-level',
  templateUrl: './level.component.html',
  styleUrls: ['./level.component.css']
})


export class LevelComponent implements OnInit {
  levelInfo: Level;
  student: Student;
  studentProgress: any;
  noProgress = false;
  series: ApexAxisChartSeries;
  chart: ApexChart;
  todayPoints = 0;
  todayProgresses = [];
  progresses = {};
  lang = lang;
  locale = 'es';
  public chartOptions: Partial<ChartOptions>;

  constructor(private route: ActivatedRoute, private handleRequest: HandleRequestService) { }

  ngOnInit() {
    var ln = localStorage.getItem('locale');
    if(ln != null) {
      this.locale = ln;
    }
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDate();
    const today = new Date(year, month, day);
    const tomorrow = this.addDays(today, 1);

    this.route.data.subscribe((data: Data) => {
      if (data.hasOwnProperty('profile')) {
        const response = data.profile as APIResponse;
        if (!response.error) {
          this.student = response.data;
        }
      }

      if (data.hasOwnProperty('levels')) {
        const response = data.levels as APIResponse;
        if (!response.error) {
          this.levelInfo = response.data;
        }
      }

      if (data.hasOwnProperty('progress')) {
        const response = data.progress as APIResponse;
        if (!response.error) {
          this.studentProgress = response.data;
          this.noProgress = Object.keys(this.studentProgress).length === 0 && this.studentProgress.constructor === Object;

          for(var item of this.studentProgress.level_points) {
            this.progresses[item.level] = item;
          }
          this.checkCurrentLevel();

        }
      }

      this.chartOptions = {
        series: [this.studentProgress.level_data.step/this.levelInfo.levels.length * 100],
        chart: {
          height: 150,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            startAngle: -180,
            endAngle: 180,
            dataLabels: {
              show: false
            },
            track: {
              strokeWidth: "400%"
            }
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#62CC69"],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
          }
        },
      };

      this.handleRequest.get(`/progress/${today.getTime()}/${tomorrow.getTime()}`).then(
        (res: APIResponse) => {
          console.log(res.data);

          var count = 0;
          var points = 0;
          var status_list = {
            a: 'Audible',
            s: 'Static',
            f: 'Flash'
          };

          for(var item of res.data.progresses) {
            if(item.points != -1)  {
              points += item.points;
              count++;
              var todayProgress = {};
              todayProgress['points'] = item.points;
              todayProgress['text'] = `Lesson ${item.group}, day ${item.day}, ${status_list[item.type]}`;
              this.todayProgresses.push(todayProgress);
            }
          }

          if(count != 0)

            this.todayPoints = Math.round(points/count);

        }
      )

    });
  }

  getAvatar() {
    
    if (typeof  this.student.avatar != "object") {
      return this.student.avatar;
    } else {
      return 'assets/images/avatar.png';
    }
  }

  checkCurrentLevel() {
    const levels = this.levelInfo.levels;
    let currentIndex;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < levels.length; i++) {
      if (!this.noProgress && levels[i]._id === this.studentProgress.level)  {
        currentIndex = i;
      }
      if (!isNaN(currentIndex)) {
        if (currentIndex > i) {
          levels[i].status = 'done';
          var progress = this.progresses[levels[i]._id];
          if(progress) {
            if(progress.points == -1) {
              levels[i].points = 100;
            } else {
              levels[i].points = progress.points;
            }

            levels[i].date = this.convertMillisecondsToDate(progress.date);

          } else {
            levels[i].points = 100;
            levels[i].date = "12 Jan 22";
          }
        } else if (currentIndex < i) {
          levels[i].status = 'pending';
        } else  {
          levels[i].status = 'current';
          var progress = this.progresses[levels[i]._id];
          if(progress) {
            if(progress.points == -1) {
              levels[i].points = 100;
            } else {
              levels[i].points = progress.points;
            }
  
            levels[i].date = this.convertMillisecondsToDate(progress.date);
  
          } else {
            levels[i].points = 100;
            levels[i].date = "12 Jan 22";
          }
        }
      } else {
        levels[i].status = 'done';
        var progress = this.progresses[levels[i]._id];
        if(progress) {
          if(progress.points == -1) {
            levels[i].points = 100;
          } else {
            levels[i].points = progress.points;
          }

          levels[i].date = this.convertMillisecondsToDate(progress.date);

        } else {
          levels[i].points = 100;
          levels[i].date = "Jan 12, 2022";
        }
      }
    }

    console.log(this.levelInfo.levels);
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
  }

  convertMillisecondsToDate(milliseconds) {
    const dateObject = new Date(milliseconds)

    const humanDateFormat = dateObject.toLocaleString("en-US", {day: "numeric", month: "short", year: "numeric"});

    return humanDateFormat;
  }
}
