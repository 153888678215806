import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UtilService } from '../shared/services/util.service';
import { STUDENT_AUTH } from '../shared/services/app-config';
import { Student } from '../shared/models/student';
import { lang } from 'src/lang/lang';

declare var $;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  isLoggedIn = false;
  student: Student;
  lang = lang;
  locale = 'es';

  constructor(private router: Router,
              private utilService: UtilService) { }

  ngOnInit() {
    var ln = localStorage.getItem('locale');
    if(ln != null) {
      this.locale = ln;
    }

    $('.navbar-toggler, .overlay').on('click', () => {
        $('.mobileMenu, .overlay').toggleClass('open');
    });

    $('.navbar-toggler').click(() => {
        if ($('.fa-bars').attr('src') === 'assets/icons/burger.png') {
            $('.fa-bars').attr('src', 'assets/icons/burger2.png' );
        } else {
            $('.fa-bars').attr('src', 'assets/icons/burger.png');
        }
    });

    this.utilService.retrieveLocalStorage(STUDENT_AUTH).subscribe(x => {
      if (x) {
        this.isLoggedIn = true;
        this.student = x as Student;
      } else {
        this.isLoggedIn = false;
      }
    });

    this.router.events.subscribe(
      val => {
        if (val instanceof NavigationEnd) {
          if (this.checkIfDashboard(this.router.url)) {
            this.isLoggedIn = true;
          } else {
            this.isLoggedIn = false;
          }
        }
      }
    );
  }

  checkIfDashboard(url) {
    if (url.search('dashboard') >= 0) {
      return true;
    } else {
      return false;
    }
  }

  getAvatar() {
    if ((typeof  this.student.avatar) != "object") {
      return this.student.avatar;
    } else {
      return 'assets/images/avatar.png';
    }
  }

  changeLang (ln) {
    localStorage.setItem('locale', ln);
    location.reload();
  }
}
