import { Component, OnInit, OnDestroy } from '@angular/core';
import { UtilService } from 'src/app/shared/services/util.service';
import { STUDENT_AUTH } from 'src/app/shared/services/app-config';
import { Subscription } from 'rxjs';
import { lang } from 'src/lang/lang';
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit, OnDestroy {
  isLoggedIn = false;
  subscription: Subscription;
  lang = lang;
  locale = 'es';
  constructor(private utilService: UtilService) { }

  ngOnInit() {
    var ln = localStorage.getItem('locale');
    if(ln != null) {
      this.locale = ln;
    }

    this.subscription = this.utilService.retrieveLocalStorage(STUDENT_AUTH).subscribe(x => {
      if (x) {
        this.isLoggedIn = true;
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
