import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIResponse } from 'src/app/shared/models/api-response';
import { Student } from 'src/app/shared/models/student';
import { lang } from 'src/lang/lang';

@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.css']
})
export class DashboardHomeComponent implements OnInit {
  student: Student;
  studentProgress: any;
  lang = lang;
  locale = 'es';
  constructor(private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    var ln = localStorage.getItem('locale');
    if(ln != null) {
      this.locale = ln;
    }
    this.route.data.subscribe(x => {
      if (x.hasOwnProperty('profile')) {
        const response = x.profile as APIResponse;
        this.student = response.data as Student;
      }

      if (x.hasOwnProperty('progress')) {
        const response = x.progress as APIResponse;
        if (!response.error) {
          this.studentProgress = response.data;
        }
      }
    });
  }

  getAvatar() {
    
    if (typeof  this.student.avatar != "object") {
      return this.student.avatar;
    } else {
      return 'assets/images/avatar.png';
    }
  }

  changeAvatar() {
    this.router.navigate(['/dashboard/avatar']);
  }

  comingSoon() {
    alert('Update coming...');
  }

}
