import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { catchError, map, tap } from 'rxjs/operators';
import { APIResponse } from 'src/app/shared/models/api-response';
import { Student } from 'src/app/shared/models/student';
import { Level } from 'src/app/shared/models/student_level';
import io from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/shared/services/util.service';
import { STUDENT_AUTH } from 'src/app/shared/services/app-config';
import { HandleRequestService } from 'src/app/shared/services/handle-request.service';
import { HttpErrorResponse } from '@angular/common/http';

import { HttpClientModule } from '@angular/common/http';
import { CalendarOptions } from '@fullcalendar/angular'; 
import { formatDate } from "@fullcalendar/core";
import { lang } from 'src/lang/lang';
declare var JitsiMeetExternalAPI: any;

declare var $;

@Component({
  selector: 'app-classroom',
  templateUrl: './classroom.component.html',
  styleUrls: ['./classroom.component.css']
})
export class ClassroomComponent implements OnInit, OnDestroy {
  levelInfo: Level;
  student: Student;
  studentProgress: any;
  noProgress = false;
  lessons: any = {};
  selectedLesson: any = {};
  isClassActive;
  classEnded;
  message;
  lastStartDate = "";
  lastEndDate = "";
  startTime = "";
  endTime = "";
  currentStatus = "";
  isMeeting = false;
  lang = lang;
  locale = 'es';
  calendarOptions: CalendarOptions = {
    initialView: 'timeGridDay',
    headerToolbar: {
      left: "prev,next today",
      center: "title",
      right: "timeGridWeek,timeGridDay,listWeek",
    },
    themeSystem: "bootstrap",
    events: [],
    // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
    editable: false,
    selectable: false,
    selectMirror: true,
    allDaySlot: false,
    dayMaxEvents: true,
    weekends: true,
    datesSet: this.handleMonthChange.bind(this),
  };


  jistiDomain: string = "meet.jit.si"; // For self hosted use your domain
  jistiRoom: any;
  jistiOptions: any;
  jistiApi: any;

  constructor(private route: ActivatedRoute,
              private utilService: UtilService,
              private handleRequest: HandleRequestService,
              private router: Router) {
    this.utilService.retrieveLocalStorage(STUDENT_AUTH).subscribe(
      (stud: Student) => {
        // this.student = stud;
      }
    );
  }

  ngOnInit() {
    var ln = localStorage.getItem('locale');
    if(ln != null) {
      this.locale = ln;
    }

    setInterval(() => {
      let events = [];
      let currentTime = new Date();
      let currentMiliseconds = currentTime.getTime();
      for (const [key, item] of Object.entries(this.lessons)) {
        let currentStatus = "future";

        let startTimestamp = Date.parse(item['startTime']);
        let endTimestamp = Date.parse(item['endTime']);

        if(currentMiliseconds >= endTimestamp) {
          currentStatus = "past";
        } else if (currentMiliseconds >= startTimestamp && currentMiliseconds < endTimestamp) {
          currentStatus = "current";
        } else if (currentMiliseconds < startTimestamp && currentMiliseconds > startTimestamp - 300000) {
          currentStatus = "ready";
        } else {
          currentStatus = "future";
        }

        events.push({
          id: item['_id'],
          title: item['title'],
          start: item['startTime'],
          end: item['endTime'],
          classNames: [item['status'], item['lessonType'], item['age_bracket'], "group"+item['group'], currentStatus]
        });
        this.calendarOptions = {
          initialView: 'timeGridDay',
          headerToolbar: {
            left: "prev,next today",
            center: "title",
            right: "timeGridWeek,timeGridDay,listWeek",
          },
          themeSystem: "bootstrap",
          events: events,
          // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
          editable: false,
          selectable: false,
          selectMirror: true,
          allDaySlot: false,
          dayMaxEvents: true,
          weekends: true,
          datesSet: this.handleMonthChange.bind(this),
          eventClick: this.handleEventClick.bind(this),
        };
      }


    }, 1000);

    this.route.data.subscribe((data: Data) => {
      if (data.hasOwnProperty('profile')) {
        const response = data.profile as APIResponse;
        if (!response.error) {
          this.student = response.data;
        }
      }

      if (data.hasOwnProperty('progress')) {
        const response = data.progress as APIResponse;
        if (!response.error) {
          this.studentProgress = response.data;
          this.noProgress = Object.keys(this.studentProgress).length === 0 && this.studentProgress.constructor === Object;
        }
      }

    });
  }

  ngOnDestroy(): void {
  }

  handleMonthChange(event) {
    if (this.lastStartDate !== event.startStr && this.lastEndDate !== event.endStr) {
      this.lastStartDate = event.startStr;
      this.lastEndDate = event.endStr;

      let startTimestamp = Date.parse(event.startStr);
      let endTimestamp = Date.parse(event.endStr);

      this.lessons = {};

      this.utilService.appIsLoading.next(true);
      this.handleRequest.get(`/classroom/rooms/${this.student.teacher}/${startTimestamp}/${endTimestamp}`).then(
        (res: APIResponse) => {
          this.utilService.appIsLoading.next(false);
          if (!res.error) {
            for (const [key, item] of Object.entries(res.data)) {
              this.lessons[item['_id']] = item;
            }
          } else {
            alert('Request failed, please try again');
          }
        }, (errorResponse: HttpErrorResponse) => {
          this.utilService.appIsLoading.next(false);
          const message = errorResponse.error.message;
          alert(message);
        }
      );
    }
  }

  handleEventClick(clickInfo) {
    this.selectedLesson = this.lessons[clickInfo.event.id];

    this.lessonStatus();

    this.startTime = formatDate(this.selectedLesson.startTime, {
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',

    });

    this.endTime = formatDate(this.selectedLesson.endTime, {
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    });

    $('#classModal').modal();
  }

  retrieveLessons() {
    this.utilService.appIsLoading.next(true);
    this.handleRequest.get(`/classs/${this.studentProgress.level}/lessons`).then(
      (res: APIResponse) => {
        this.utilService.appIsLoading.next(false);
        if (!res.error) {
         
        } else {
          alert('Request failed, please try again');
        }
      }, (errorResponse: HttpErrorResponse) => {
        this.utilService.appIsLoading.next(false);
        const message = errorResponse.error.message;
        alert(message);
      }
    );
  }

  getAvatar() {
    
    if (typeof  this.student.avatar != "object") {
      return this.student.avatar;
    } else {
      return 'assets/images/avatar.png';
    }
  }

  close() {
    $('#classEndedModal').modal('hide');
    this.router.navigate(['/dashboard']);
  }

  closeMeeting() {
    this.isMeeting = false;
    $('#meetingModal .modal-content').html('');
    $('#meetingModal').modal('hide');
  }

  joinMeeting() {
    this.isMeeting = true;
    this.jistiRoom = this.selectedLesson.slug;
    $('#meetingModal .modal-content').html(
      `<div id="jitsi-iframe"></div>`
    );

    this.jistiOptions = {
      roomName: this.jistiRoom,
      height: 800,
      configOverwrite: { prejoinPageEnabled: false },
      interfaceConfigOverwrite: {
          // overwrite interface properties
      },
      parentNode: document.querySelector('#jitsi-iframe'),
      userInfo: {
          displayName: this.student.first_name
      }

    }

    this.jistiApi = new JitsiMeetExternalAPI(this.jistiDomain, this.jistiOptions);

    this.jistiApi.addEventListeners({      
        videoConferenceLeft: this.closeMeeting,
    });

    $('#meetingModal').modal();

  }

  navigateToClasswork(id, index) {

  }

  lessonStatus() : string {
    let currentTime = new Date();
    let currentMiliseconds = currentTime.getTime();

    let currentStatus = "future";

    let startTimestamp = Date.parse(this.selectedLesson.startTime);
    let endTimestamp = Date.parse(this.selectedLesson.endTime);

    if(currentMiliseconds >= endTimestamp) {
      currentStatus = "past";
    } else if (currentMiliseconds >= startTimestamp && currentMiliseconds < endTimestamp) {
      currentStatus = "current";
    } else if (currentMiliseconds < startTimestamp && currentMiliseconds > startTimestamp - 300000) {
      currentStatus = "ready";
    } else {
      currentStatus = "future";
    }

    this.currentStatus = currentStatus;

    return currentStatus;
  }

}
